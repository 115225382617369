import React, { useEffect, useState } from "react";
import { NavLink, useHistory, RouteComponentProps, useParams } from "react-router-dom";
import $ from "jquery";

import axios from "axios";
import { BASE_URL } from "../../../variables/api";
import Swal from "sweetalert2";


interface StudentsExamsProps extends RouteComponentProps<{ search: string }> {
  count: number;
  searchexams?: string;
  studentsExams: Array<any>;
  subjects: Array<any>;
  GetStudentsExams: any;
  GetSubjects: any;
}

const StudentsExams = (props: any) => {
  const {exam_id}:any = useParams();
  const navigate = useHistory();
  const [studentsExams, setStudentsExams] = useState([])
  require("datatables.net");
  require("../../MyComponents/dataTables.bootstrap4.min");
  
  useEffect(() => {
    Swal.showLoading()
    axios.post(`${BASE_URL}admin/getstudentsexams.php`, { exam_id: exam_id }).then((res: any) => {
      $('#dataTable').DataTable().destroy();
      setStudentsExams(res.data.data.exams)
      Swal.close();
    }).catch(() => {
      Swal.close();
    })
  }, [])


  useEffect(() => {
    $('#dataTable').DataTable();
  }, [studentsExams]);

  const grading = (id: any) => {
    navigate.push({
      pathname: "/assessmentViewComponent",
      search: "?id=" + id + "&exammode=gradingModeTeacher",
    });
  };

  return (
    <div>
      {/* <button className="btn bg-info text-white mb-2" onClick = {this.addAssessmentHandler}>Add Assessment</button> */}
      <NavLink to={"/dashboard/assessments"} className="btn btn-secondary mb-3">
        <i className="fa fa-arrow-left" aria-hidden="true" />
      </NavLink>
      <div>
        {
        <table
          id="dataTable"
          className="table-hover table-bordered text-center"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Exam name</th>
              <th>Subject</th>

              <th>Student Id</th>
              <th>Student name</th>
              <th>Group name</th>
              <th>Release date</th>
              <th>Submission date</th>
              <th>Status</th>
              <th>Grades</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {studentsExams?.map(
              (studentsExam: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>{studentsExam.examname}</td>
                    <td>{studentsExam.subject}</td>
                    <td>{studentsExam.student_id}</td>
                    <td>{studentsExam.studentname}</td>
                    <td>{studentsExam.groupname}</td>
                    <td>{studentsExam.releasedate}</td>
                    <td>{studentsExam.submitdate}</td>

                    <td>{studentsExam.status}</td>


                    {studentsExam.status == "Pending" &&
                      studentsExam.published == 1 ? (
                      <>
                        <td> </td>
                        <td> </td>
                      </>
                    ) : studentsExam.status == "Submitted" ? (
                      <>
                        <td></td>
                        <td>
                          <button
                            className="btn bg-info text-white mb-2"
                            onClick={() => {
                              grading(studentsExam.id);
                            }}
                          >
                            Grading
                          </button>{" "}
                          <span> </span>{" "}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{`${studentsExam.total_score} / ${studentsExam.outof}`}</td>
                        <td>
                          <button
                            className="btn bg-info text-white mb-2"
                            onClick={() => {
                              grading(studentsExam.id);
                            }}
                          >
                            View
                          </button>{" "}
                          <span> </span>{" "}
                        </td>
                      </>
                    )}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
        }
      </div>

    </div>
  );

}
export default StudentsExams

