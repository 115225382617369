import React from "react";
import { Component } from "react";
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import '../../../css/dataTables.bootstrap4.min.css';
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { actionCreators as AssessmentActionCreator } from "../../../store/Assessment";
import Swal from "sweetalert2";




const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  GetSubjects: () =>
    dispatch(AssessmentActionCreator.GetSubjects()),
    AddSubject: (data: any) =>
    dispatch(AssessmentActionCreator.AddSubject(data)),
    resetAssessment: () =>
    dispatch(AssessmentActionCreator.resetAssessment()),
  
});


class Subjects extends Component<any, any> {

  constructor(props:any){
    super(props)
    this.onSaveSubject = this.onSaveSubject.bind(this)
  }
  state = {
    open: false,  
    subject:"",
  };
  componentDidMount() {
    $("#preloader").hide();
    require('datatables.net');
    require('../../MyComponents/dataTables.bootstrap4.min');
    $('#dataTable').DataTable();
    this.props.GetSubjects()
    this.props.resetAssessment()
  }
  onCloseModal = () => {
    this.setState({ open: false });
  };
  onConfirm = () => {
    this.props.resetAssessment()
    this.props.history.push("/dashboard/Subjects");
  };
  onSaveSubject (){
    this.props.AddSubject(this.state);

  }

 
  render() {
    let MSG = null ;
if( this.props.subjectMsg !="" ){
  Swal.fire({
    icon: 'success',
    title:'Success',
    text: this.props.subjectMsg,
  }).then(() => {
    this.onConfirm()
  })
 
}

    return (
        <div>
                  {MSG}

            <button className="btn bg-info text-white mb-2" onClick={()=>this.setState({ open: true })}>Add subject</button>
            <table id="dataTable" className="">
                <thead>
                <tr>
                    <th>Subject name</th>
                </tr>
                </thead>
                <tbody>
                  {this.props.subjects?.map((subject:any) => {
                    return ( <tr key={subject.id}>
                      <td>{subject.subject}</td>
                  </tr>)
                  })}
                   
                </tbody>
            </table>
            <Modal
                classNames={{
                  modal: "customModal_2",
                }}
                open={this.state.open}
                onClose={() => this.onCloseModal()}
                center
              >
                <div className="col-12 mx-auto align-items-center bg-question rounded p-3 text-white">
                <h3 className="text-shadow pl-1">Add subject</h3>
                <div className="row">
                  <div className="col-md-8 row align-items-start align-content-start mx-auto">
                    <label
                      className="align-self-start ml-1 mt-1"
                      htmlFor="name"
                    >
                      Name:  
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({ subject: e.target.value })
                      }
                    />
                  </div>
                  <div className="mt-5 col-md-12 text-center">
                  <button
                    className="btn btn-warning text-white"
                    disabled={this.state.subject.length==0}
                    onClick={this.onSaveSubject}
                  >
                    Add subject
                  </button>
                </div>
                </div>
                </div>
              </Modal>
        </div>
    )}
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Subjects as any)
);
function mapStateToProps(state: any) {
  return {
    subjects: state.Assessment.subjects,
    subjectMsg: state.Assessment.subjectMsg,
    
  };
}
