import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { BASE_URL } from "../../../variables/api";
import Modal from "react-responsive-modal";
import $ from "jquery"

const PublishAssessmentModal = (props: any) => {

    const [Students, setStudents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [allCheckBox , setAllCheckBox] = useState(false);
    const setSelected = (e: any, id: any) => {
        let tempCheckboxs: any = [...Students];
        let findIndex = tempCheckboxs.findIndex((c: any) => c.id == id);
       
        tempCheckboxs[findIndex].checked = !tempCheckboxs[findIndex].checked;
   
        setStudents(tempCheckboxs)

    }

    useEffect(() => {

        require("datatables.net");
        require("../../MyComponents/dataTables.bootstrap4.min");
    }, [])
    useEffect(() => {
        try {

            axios.get(
                `${BASE_URL}admin/getgroupstudents.php?group_id=${props.group_id}&isRetake=${props.isRetake}&exam_id=${props.selectedAssessment_id}`
            )
                .then((res) => {
                    res.data.data?.forEach((student: any) => {
                        student.checked = false
                    });
                    $("#dataTable2").DataTable().destroy();
                    setStudents(res.data.data);
                    $('#dataTable2').DataTable( {
                        "lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]]
                        ,pageLength:-1
                    })
                    Swal.close();
                })
                .catch((err) => {

                });
        } catch (error) {
            console.error('Error fetching Students:', error);
        }
    }, [props.group_id])



    const submitHandler = (e: any) => {
        e.preventDefault();
        props.PublishAssessment(Students)
        props.handleModalClose()
        props.setIsRetake(false);
    }


    const handleCheckAll = (e:any)=>{
        let newCheckValue= !allCheckBox
        setAllCheckBox(newCheckValue) 
        let tempCheckboxs: any = [...Students];
        for (let index = 0; index < tempCheckboxs.length; index++) {
            tempCheckboxs[index].checked =newCheckValue;
            
        }
        setStudents(tempCheckboxs)
    }

    return (
        <div  >


            <Modal
               
                classNames={{
                    modal: "customModal_2"
                }}
                open={props.modalOpen}
                onClose={() => { props.handleModalClose(); props.setIsRetake(false); }}
                center
            >

                <form onSubmit={submitHandler}>

                    <div className="table-responsive mt-5">

                        <table id="dataTable2" className="table table-borderd">
                            <thead>
                                <tr>
                                    <th onClick={(e) => { handleCheckAll(e)}}>  <input type="checkbox" checked={allCheckBox}  /></th>
                                    <th>Name </th>
                                    <th>ID </th>

                                </tr>
                            </thead>
                            <tbody>

                                {Students?.map((checkbox: any) => (


                                    <tr key={checkbox.id} className='m-3' >
                                        <td >
                                        <input type="checkbox" checked={checkbox.checked} onChange={(e) => { setSelected(e, checkbox.id) }} />
                                        </td>
                                        <td >

                                            <label style={{cursor:"pointer"}} onClick={(e) => { setSelected(e, checkbox.id) }} >
                                                
                                                {checkbox.displayname}
                                            </label>
                                        </td>
                                        <td> {checkbox.school_uid.split('-').pop()}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>


                    <div className="text-center">

                        <button type="submit" className="btn btn-success " >Submit</button>
                    </div>
                </form>


            </Modal>




        </div>
    )
}


export default PublishAssessmentModal