import React from 'react';
import { useHistory } from "react-router-dom";
import '../css/login.css';

let emaildomain =localStorage.getItem("emaildomain")

if(localStorage.getItem("emaildomain") == "iatseg.org"){
  let selected_school :any = localStorage.getItem("selected_school")
  emaildomain =JSON.parse(selected_school)?.value
}

function Msg(props:any){
    const hist = useHistory();
    setTimeout(()=>{
        goto(props.url)
    },2000);
    
    function goto(url:any){   
        hist.push(url);
    }
    return(
        <div className="container">
            <div className="row text-center align-content-center justify-content-center">
                <div className="col-12 col-md-10 col-lg-8 border border-dark rounded mt-5 p-5 color2">
                    <img src={`/assets/${emaildomain}/logo.png`} alt="Logo" width="150" height="150" className="m-3"/>
                    <h1>{props.msg}</h1>
                </div>                
            </div>
            
        </div>

    );

}

export default Msg;