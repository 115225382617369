import React, { useState } from "react";
import { DropDownNav } from "../MyComponents/MyNavComponents";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import { Translate } from "../../helpers/Translate";
import { transcode } from "buffer";
import jwt_decode from "jwt-decode";

import Cookies from "js-cookie";
// function getMenu(props:MenuItem){
//   let t = new Translate();
//     const subMenu = props.MenuItems.map((m:MenuItem) => {
//             return(
//                 getMenu(m)
//             );
//     });
//     const pages = props.Pages.map((p:Page) => {
//       return(
//       <NavLink key={p.PageId} className="nav-link" to={"/dashboard/"+p.Name.replace(" ","")}>{t.translate(p.Name)}</NavLink>
//       );
//     });

//   return(
//     <DropDownNav key={props.MenuItemId} name={props.Name} className="fa fa-lock">
//       {subMenu}
//       {pages}
//     </DropDownNav>
//   );
// }
interface MenuItem {
  MenuItemId: number;
  Name: string;
  MenuItems: Array<MenuItem>;
  Pages: Array<Page>;
}
interface Page {
  PageId: number;
  Name: string;
}

export default function SideNav(props: any) {
  let t = new Translate();
  // const subMenu = props.Menu.MenuItems.map((m:MenuItem) => {
  //         return(
  //             getMenu(m)
  //         );
  // });
  {
    /*<NavLink  className="nav-link" to={"/dashboard/users"} key ="1"> Users</NavLink>,*/
  }
  const token = Cookies.get("quiztoken");
  let decodedToken: any = {};

  decodedToken = jwt_decode(token || "");
  
  const pages = [
    <NavLink key="3" className="nav-link" to={"/dashboard/assessments"}>
      Assessments
    </NavLink>,
  ];

  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <Nav className="nav">
            {pages}

            {/*             <div className="sb-sidenav-menu-heading">Addons</div>
            <NavLink className="nav-link" to="charts.html">
              <div className="sb-nav-link-icon"><i className="fa fa-area-chart"></i></div>
            Charts
          </NavLink>
            <NavLink className="nav-link" to="tables.html">
              <div className="sb-nav-link-icon"><i className="fa fa-table"></i></div>
            Tables
            </NavLink> */}
          </Nav>
        </div>
      </nav>
    </div>
  );
}
