import React, { useEffect, useState, FormEvent } from 'react'
import MultiStepProgressBar from './MultStepProgress'
import { useMultiStepExam } from './useMultiStepExam'
import { count } from 'console'
import { MathJax } from 'better-react-mathjax'

const MultiStepFunc = (props: any) => {
    const [examsArrLocal, setExamsArrLocal] = useState([])
    const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next, goTo }: any =
        useMultiStepExam(examsArrLocal)

    useEffect(() => {
        setExamsArrLocal(props.examsArr)
    }, [props.examsArr])

    useEffect(() => {
        if (!isLastStep && props.buttonRef.current) {
            props.buttonRef.current.disabled = true;
        }
        else if (props.buttonRef.current) {
            props.buttonRef.current.disabled = false;
        }
    }, [isLastStep])
    // useEffect(() => {
    //     if (currentStepIndex >= 0) {

    //     }
    // }, [currentStepIndex])

    function onSubmit(e: FormEvent) {
        e.preventDefault()

        if (!isLastStep) {
            return next()
        }
        props.submitPerQuestion()
    }

    function onNext(e: FormEvent) {
        e.preventDefault()

        if (!isLastStep) {
            return next()
        }
        props.submitPerQuestion()
    }
    if (props.examsArr === undefined || props.examsArr === null || props.examsArr.length === 0) {
        return <div></div>
    } else
        return (
            <div
                style={{
                    position: "relative",
                    background: "white",
                    border: "1px solid black",
                    padding: "1rem",
                    // margin: "1rem",
                    borderRadius: ".5rem",
                    fontFamily: "Arial",
                    // maxWidth: "max-content",
                }}
            >
                {/* <form onSubmit={onSubmit} id="dddd"> */}
                <div id="dddd">
                    <div className='row'>
                        <div className="col-12">
                            <MultiStepProgressBar steps={steps} currentStepIndex={currentStepIndex} goTo={goTo} />
                        </div>
                    </div>
                    {/* <div style={{ position: "absolute", top: ".5rem", right: ".5rem", color: "#000", fontSize: "20px" }}>
                    {currentStepIndex + 1} / {steps.length}
                </div> */}

<MathJax>
                    {step}
                    </MathJax>
                    <div
                        style={{
                            marginTop: "1rem",
                            display: "flex",
                            gap: ".5rem",
                            justifyContent: "flex-end",
                        }}
                    >

                        {!isFirstStep && (
                            <button className='btn btn-secondary' type="button" onClick={back}>
                                Back
                            </button>
                        )}
                        {!isLastStep && (
                            <button className='btn btn-success' type="button" onClick={onNext}>{isLastStep ? "Finish" : "Next"}</button>

                        )}
                        {/* <button className='btn btn-primary' type="submit">{isLastStep ? "Finish" : "Next"}</button> */}
                    </div>
                </div>
                {/* </form> */}
            </div>

        )
}

export default MultiStepFunc