import React, { useEffect, useRef, useState } from "react";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { MapSecondsToTime, makeId, requestWithAxios } from "../../helpers/func";
import { handleCount } from '../../helpers/validation';
import Swal from "sweetalert2";
import DifficultLevel from "./smallComponents/DifficultLevel";
import { BASE_URL } from "../../variables/api";



import CardShowFilesList from './CardShowFilesList';
import { useSelector } from 'react-redux';
import CardTable from './CardTable';
import AWS from 'aws-sdk';
import DocView from './DocView';
import DocumentModal from './DocumentModal';
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";



const rowProps = ["file_name", "driveid", "type", "userName"];

let docs: any = [{ uri: "" }];

const headers = {
  "Sec-Fetch-Mode": "cors",
  "Sec-Fetch-Site": "cross-site"
};
const grading_dot_radius = "20px";

const imgTypes =
  [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg",
    "tiff",
    "ico",
    "webp",
    "heic",
    "jfif",
    "heif"
  ]
const videoTypes = [
  "mp4",
  "ogg",
  "avi",
  "mov",
  "flv",
  "asf",
  "amv",
  "3gp",
  "mpeg"
]

const REACT_APP_AWS_REGION = "us-east-2";
const REACT_APP_AWS_ACCESS_KEY_ID = "AKIAQ4XA32XRLCWLSKFG";
const REACT_APP_AWS_SECRET_ACCESS_KEY = "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";
const S3_BUCKET = "ivyis"

AWS.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY
})
const s3 = new AWS.S3(
  {
    params: { Bucket: S3_BUCKET },
    region: REACT_APP_AWS_REGION
  }
);

function truncateString(str: any, maxLength: any) {
  return str.length > maxLength ? str.slice(0, maxLength - 1) + '...' : str;
}


function customUrlEncode(url: any) {
  let encodedUrl = '';

  for (const char of url) {
    if (/[A-Za-z0-9\-_\.~:\/]/.test(char)) {
      // Characters that are allowed to remain unchanged
      encodedUrl += char;
    } else {
      // Encode other characters
      encodedUrl += encodeURIComponent(char);
    }
  }

  return encodedUrl;
}


const ProcessCardSingleState = (props: any) => {

  const [userName, setUserName] = useState<any>("");
  const [openDocModal, setOpenDocModal] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const [rowFocusIndex, setRowFocusIndex] = useState(-1);
  const [rowFocusFlag, setRowFocusFlag] = useState(false);
  const [rowFocusTmpName, setRowFocusTmpName] = useState("");
  // const [loadingBar, setLoadingBar] = useState("0");
  const [loadingBarArr, setLoadingBarArr] = useState<any>([])
  const [showProgressArr, setShowProgressArr] = useState<any>([])
  // const [showProgress, setShowProgress] = useState(false);



  const [state, setState] = useState<any>({
    id: makeId(10),
    type: props?.type ?? "ProcessCard",
    show_objectives: false,
    shuffle_options: false,
    studentexam_id: null,
    objectives: "",
    feedback: "",
    mode: "",
    total_mark: "",
    score: "0",
    header: '',
    headertxt: '',
    required: true,
    difficult: "Basic Level",
    cognitive: "Remembering",
    alert: null,
    spenttime: 0,
    calibration: {
      kpi: [],
      files: [],
    },
  });


  let count = useRef<any>()
  useEffect(() => {
    if (props.quest != undefined) {

      let Question = { ...props.quest };
      if (typeof Question.calibration == "string") {
        Question.calibration = JSON.parse(Question.calibration);
      } else {
        Question.calibration = Question.calibration;
      }
      const len = Question.calibration?.files?.length ?? 0;
      const loadingBarArrTmp = [];
      const showProgressArrTmp = [];
      for (let index = 0; index < len; index++) {
        showProgressArrTmp.push(false);
        loadingBarArrTmp.push("100%");
      }
      setLoadingBarArr(loadingBarArrTmp);
      setShowProgressArr(showProgressArrTmp);

      if (Question.mode == "examMode") {

        if (!Question.spenttime || Question.spenttime == null || Question.spenttime == "null") {
          Question.spenttime = 0
        }

        const intervalId = setInterval(() => {
          setState((prev: any) => { return { ...prev, spenttime: ++prev.spenttime } });
        }, 1000)

        Question.intervalId = intervalId

      }
      setState(Question)

    } else {
      setState((prev: any) => ({ ...prev, mode: "editingMode" }))
      Swal.close()
    }
  }, []);

  useEffect(() => {
    return () => {
      if (state.mode == "examMode") {

        // props.onSaveQuestion({ ...state , spenttime : count.current.value })

        clearInterval(state.intervalId)
        requestWithAxios(
          BASE_URL + "student/savespenttime.php",
          { spenttime: count.current.value, "studentexamquestion_id": state.id }
        );
      }
    }
  }, [state.intervalId])
  const onSaveQuestion = (updatedState: any) => {

    let require = handleCount(updatedState.header);
    // check table not empty
    let kpiTableRequired = false;

    const kpiTable = updatedState?.calibration?.kpi;
    kpiTable?.forEach((row: any) => {
      if (kpiTableRequired === false) {
        if (handleCount(row.kpi_name) === true) {
          kpiTableRequired = true;
        }
      }
    });
    let kpiTableCount = kpiTable.length;

    if (require) {
      Swal.fire({
        icon: 'error',
        text: ' Question Header is required',
      }).then(() => {

      })
    } else if (kpiTableRequired) {
      Swal.fire({
        icon: 'error',
        text: 'KPI Rows names are required',
      }).then(() => {
      })
    } else if ((updatedState.total_mark == 0) || (updatedState.total_mark == ".")) {
      Swal.fire({
        icon: 'error',
        text: ' Total mark is required',
      }).then(() => {

      })
    } else if (1 > kpiTableCount) {
      Swal.fire({
        icon: 'error',
        text: 'KPI Rows must be there ',
      }).then(() => {

      })
    } else {

      props.onSaveQuestion(updatedState)
    }

  }



  const kpiTableRowFactory = () => {
    const newRow = {
      serial_hash: makeId(10),
      kpi_name: "",
      grading: false,
    };
    let tempState = { ...state }
    tempState.calibration?.kpi?.push(newRow);
    setState(tempState);

  }


  const save2php = (updatedState: any) => {
    setState(updatedState);

    if (updatedState.mode === "examMode") {

      let tempState = { ...updatedState }
      const tmp = [...tempState?.calibration?.files]
        ?.filter((row: any) => (!row.hasFailedFlag)).map((row: any) => {
          const obj: any = {}
          rowProps.forEach((rowProp: any) => {
            if (row.hasOwnProperty(rowProp)) { obj[rowProp] = row[rowProp]; }
          });
          return obj;
        })
      // tempState.calibration.files = [...tmp];
      tempState.calibration = {
        kpi: tempState?.calibration?.kpi,
        files: [...tmp],
      }
      requestWithAxios(
        BASE_URL + "student/saveq.php",
        tempState
      );

      onSaveQuestion(updatedState)

    }

  };

  useEffect(() => {

    let user_name: any = "";
    let token: any = null;
    if ("examMode" == state?.mode) {
      token = Cookies.get("tokentest");
    } else {
      token = Cookies.get("quiztoken");
    }
    if (token) {
      const decodedToken: any = jwt_decode(token);
      user_name = decodedToken?.data?.userName;
    }
    if (user_name) {
      setUserName(user_name)
    }
    return () => {

    }
  }, [])


  const attachmentTableRowFactory = (file_name: any, type: any, driveid: any) => {
    const newRow: any = {
      driveid,
      file_name,
      type,
      userName,
      hasFailedFlag: false,
    };

    let updatedState = { ...state }

    updatedState?.calibration?.files?.push(newRow);
    setState(updatedState);
  }



  const handleFileUpload2s3 = async (file: any, file_index: any) => {

    // const file_index = state?.calibration?.files?.length ?? 0;
    // setLoadingBar("0");
    loadingBarArr.push("0");
    setLoadingBarArr((x: any) => [...x])
    // setShowProgress(true)
    showProgressArr.push(true);
    setShowProgressArr((x: any) => [...x])
    //  e.preventDefault();
    // Swal.showLoading();


    if (!file) {
      console.error('No file selected for upload');
      return;
    }
    // Replace with your bucket name
    const bucketName = 'ivyis';
    // Unique object key for the file
    const rootPath = "Assessments";

    const file_name = file.name;
    // Extract the file extension
    const fileExtension = file_name?.split(".")?.pop();

    // Remove the original file extension
    const baseName = file_name?.replace(`.${fileExtension}`, "");

    // Create a new Date object
    const currentDate = new Date();

    // Get the individual components of the timestamp
    const year = currentDate.getFullYear(); // e.g., 2024
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // e.g., 04
    const day = currentDate.getDate().toString().padStart(2, "0"); // e.g., 22
    const hours = currentDate.getHours().toString().padStart(2, "0"); // e.g., 06
    const minutes = currentDate.getMinutes().toString().padStart(2, "0"); // e.g., 30
    const seconds = currentDate.getSeconds().toString().padStart(2, "0"); // e.g., 45

    // Combine the components into a timestamp string
    const timestamp = `${year}${month}${day}${hours}${minutes}${seconds}`;



    // Append the timestamp and the file extension
    const modifiedString = `${truncateString(baseName, 50)}_${timestamp}.${fileExtension}`;


    const objectKey = `${rootPath}/${state?.id}/${modifiedString}`;
    const encodedUrl = customUrlEncode(objectKey);
    const driveid = `https://${bucketName}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/${encodedUrl}`;

    const contentType = file.type;

    attachmentTableRowFactory(file_name, contentType, driveid)


    const param = {
      ACL: 'public-read',
      Bucket: bucketName,
      Key: objectKey,
      ContentType: contentType,
      Body: file
    };


    try {

      return new Promise((resolve, reject) => {
        // Simulating asynchronous upload process
        var upload = s3.putObject(param).on("httpUploadProgress", (evt: any) => {
          const loadingBarTmp = `${((evt.loaded * 100) / evt.total)}` + "%";
          // setLoadingBar(loadingBarTmp);
          loadingBarArr[file_index] = loadingBarTmp;
          setLoadingBarArr((x: any) => [...loadingBarArr])

          // console.log("file_index");
          // console.log(file_index);

          // console.log("loadingBarTmp");
          // console.log(loadingBarTmp);
        }).promise();

        upload.then((d: any) => {

          resolve(driveid);

          // Swal.fire("Uploaded Successfully", "", "success")


          // setShowProgress(false)
          showProgressArr[file_index] = false;
          setShowProgressArr((x: any) => [...showProgressArr])
        }).catch((err) => {
          reject(err);
        })
      });

    } catch (error) {
      if ((error as any).code === 'NotFound') {
        console.error('Bucket does not exist or is not accessible');
        return;
      }
      // throw error;
    }
  }


  const catchFileUpload2s3 = async (file: any,) => {

    if (!file) {
      return
    }

    const file_index = state?.calibration?.files?.length ?? 0;
    handleFileUpload2s3(file, file_index)
      .then(() => {
        save2php(state);
        onSaveQuestion(state);
      })
      .catch((err: any) => {
        let tempState = { ...state }
        const tmp = [...tempState?.calibration?.files]
        if (tmp) {
          // console.log("file_index");
          // console.log(file_index);
          // console.log("tmp[file_index]");
          // console.log(tmp[file_index]);

          const obj = tmp[file_index]
          if (obj?.file_name === file?.name) {
            showProgressArr[file_index] = false;
            setShowProgressArr((x: any) => [...showProgressArr])
            obj.hasFailedFlag = true;
            obj.file = file;
            tempState.calibration.files = [...tmp];
          }

          setState(tempState);


        }
        Swal.fire({
          icon: 'error',
          text: 'Something wrong, Please check your internet and try again ',
        })

        return;
        // setShowProgress(false)
        // let tempState = { ...state }
        // const tmp = [...tempState?.calibration?.files]
        // if (tmp) {
        //   const len = tmp.length;
        //   if (len) {
        //     const obj = tmp[len - 1]
        //     if (obj?.file_name === file?.name) {
        //       obj.hasFailedFlag = true;
        //       obj.file = file;
        //       tempState.calibration.files = [...tmp];
        //     }
        //   }
        // }
      });

  }

  const reuploadFileUpload2s3 = async (in_file: any, file_index: any) => {

    if (!in_file) {
      return
    }
    // let file_index = 0;
    const file = { ...in_file };
    let tempState = { ...state }

    // showProgressArr
    //   ?.splice(file_index, 1);
    setShowProgressArr(showProgressArr.filter((x: any, i: number) => (i != file_index)))

    setLoadingBarArr(loadingBarArr.filter((x: any, i: number) => (i != file_index)))
    const tmp = [...tempState?.calibration?.files]?.filter((row: any, i: any) => (row.driveid != file.driveid))
    tempState.calibration.files = [...tmp];
    setState(tempState);

    catchFileUpload2s3(file.file)

  }

  const handleFileUpload2browser = (e: any) => {
    const fileInput = e?.target;
    const files_arr = [...fileInput?.files];
    fileInput.value = "";
    files_arr?.forEach((file: any) => {
      try {
        catchFileUpload2s3(file)
      } catch (error) { }
    });

  };

  const handleDocumentdriveid = (file: any) => {
    setSelectedFile(file)
    setOpenDocModal(true);
  };




  // console.log("loadingBarArr");
  // console.log(loadingBarArr);


  return (
    <div className="">

      {state.alert == "null" ? '' : state.alert}

      <div className="row rounded my-3 p-0 ll shadow bg-question">
        {state.mode == "examMode" && (
          <div className="row col-12">
            <div className="  pt-4 pl-4 col-4" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}> Q {props.index}
            </div>
            <div className="  p-3 col-6 d-flex justify-content-end" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}>
              <input type="hidden" ref={count} value={state.spenttime} />
              <div>
                Spent time : <label id="minutes">{MapSecondsToTime(Math.floor(state.spenttime / 60))}</label>:<label id="seconds">{state.spenttime > 0 ? MapSecondsToTime(+state.spenttime % 60) : "00"}</label>
              </div>
            </div>
          </div>
        )}
        <div className="col-12 row mx-auto align-items-center bg-question rounded p-3 text-white">
          <div className="col-12 row justify-content-between">

            {state.mode == "editingMode" ? (<h3>Question Setup</h3>) : null}

            <div className="d-flex flex-column">



              <div className="form-group d-flex align-items-center my-3">
                <span className="col-auto text-shadow ">
                  Total mark :
                </span>
                {state.mode === "editingMode" ||
                  state.mode === "answeringMode" ? (
                  <input
                    type="text"

                    className="col form-control"
                    name=""
                    id=""
                    value={state.total_mark}
                    onChange={(e) => {

                      if ((typeof +e.target.value == "number" && +e.target.value > 0) || e.target.value == "" || e.target.value == ".") {
                        let total_mark = e.target.value;
                        setState((prev: any) => ({ ...prev, total_mark: total_mark }));
                      }
                    }
                    }
                  />
                ) : state.mode === "examMode" ||
                  state.mode === "gradingModeTeacher" ||
                  state.mode === "gradingModeStudent" ? (
                  <React.Fragment>
                    <span className="text-shadow">{state.total_mark}</span>
                  </React.Fragment>
                ) : null}
                {state.mode === "gradingModeTeacher" || state.mode === "gradingModeStudent" ? (
                  <span className="border-left ml-2 pl-2 text-shadow">
                    Score :  {state.score}
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          {
            ("editingMode" != state?.mode) ? null :
              <div className="col-12 mb-5 row justify-content-center align-items-center">
                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                >
                  {("ProductCard" === state?.type) ? "Product Card" : "Process Card"}
                </div>
              </div>

          }


          <div className="col-12 mb-5 row justify-content-center align-items-center">
            {state.mode === "editingMode" ? (
              <div className="col-12 w-100 row justify-content-center align-items-center">
                <RichBox
                  onChange={(v: any) => {
                    setState((prev: any) => ({ ...prev, header: v }));
                  }}
                  defaultValue={state.header}
                  height={"10px"}
                ></RichBox>
              </div>
            ) : state.mode === "examMode" ||
              state.mode === "answeringMode" ||
              state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
              <React.Fragment>

                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: state.header }}
                ></div>
              </React.Fragment>
            ) : (
              <div></div>
            )}
          </div>




          {/* ================================================== */}


          {
            // kpi table
            (
              () => {

                const headerArr: any = [
                  "Name",
                ];
                if (("gradingModeTeacher" === state.mode) || (state.mode === "gradingModeStudent")) {
                  // grading is only in gradingModeTeacher so this col might not be rendered
                  headerArr.push("Grading");
                }
                if ("editingMode" === state.mode) {
                  // Action is only in editingMode so this col might not be rendered
                  headerArr.push("Action");
                }
                const kpiTable = state?.calibration?.kpi;
                const rowArr = kpiTable?.map((row: any, rowIndex: any) => {
                  // used iife to reduce typos
                  const arr: any = [
                    () => {
                      // the name can be changed in editingMode
                      let name = "";
                      const rowProp = "kpi_name";
                      if (row.hasOwnProperty(rowProp)) { name = row[rowProp]; }

                      if ("editingMode" === state.mode) {

                        let isEditingHere = false;
                        if (rowFocusFlag) {
                          if (rowFocusIndex === rowIndex) {
                            isEditingHere = true;
                          }
                        }

                        if (isEditingHere) {
                          name = rowFocusTmpName;
                        }
                        return <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e: any) => {
                            if (isEditingHere) {
                              const newName = e?.target?.value;
                              if (newName) {
                                setRowFocusTmpName(newName);
                              }
                            }
                          }}
                          onFocus={() => {
                            setRowFocusIndex(rowIndex);
                            setRowFocusFlag(true);
                            setRowFocusTmpName(name);
                          }}
                          onBlur={() => {
                            row[rowProp] = rowFocusTmpName;

                            setRowFocusIndex(-1);
                            setRowFocusFlag(false);
                            setRowFocusTmpName("");

                            // onSaveQuestion(state);
                            setState((x: any) => ({ ...x }));
                          }}
                        />

                      } else {
                        return name;
                      }
                    },
                  ];
                  if (("gradingModeTeacher" === state.mode) || (state.mode === "gradingModeStudent")) {
                    // grading is only in gradingModeTeacher so this col might not be rendered
                    arr.push(() => {
                      const rowProp = "grading";
                      let current_check: boolean = false;
                      if (row.hasOwnProperty(rowProp)) { current_check = !!(row[rowProp] - 0); }
                      if ("gradingModeTeacher" === state.mode) {
                        return <input
                          type="checkbox"
                          // value={current_check}
                          checked={current_check}
                          onChange={(e: any) => {
                            const new_checked = e?.target?.checked;
                            row[rowProp] = new_checked;
                            // onSaveQuestion(state);

                            let tmp_score = 0;
                            let len = 0;
                            kpiTable.forEach((row: any) => {
                              const row_prop = "grading";
                              if (row.hasOwnProperty(row_prop)) {
                                len++;
                                if (row[row_prop]) {
                                  tmp_score++;
                                }
                              }
                            });
                            if (len) {
                              tmp_score = (tmp_score / len) * (state?.total_mark ?? len);
                            }
                            state.score = tmp_score;

                            setState((x: any) => ({ ...x }));
                            onSaveQuestion(state);
                          }}
                        />
                      } else {
                        return <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              background: (current_check) ? "green" : "red",
                              width: grading_dot_radius,
                              height: grading_dot_radius,
                              borderRadius: "50%",
                            }}
                          />
                        </div>;
                      }
                    });
                  }
                  if ("editingMode" === state.mode) {
                    // Action is only in editingMode so this col might not be rendered
                    arr.push(() => {
                      return <button
                        className='btn btn-danger'
                        onClick={() => {
                          Swal.fire({
                            icon: 'warning',
                            text: 'Are You Sure?',
                          }).then((v: any) => {
                            if (v?.isConfirmed) {
                              state.calibration.kpi = kpiTable?.filter((x_row: any, index: any) => rowIndex != index);
                              // onSaveQuestion(state);
                              setState((x: any) => ({ ...x }));
                            }
                          })
                        }
                        }
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    });
                  }
                  return { serial_hash: row.serial_hash, arr }

                });

                let addBtn = null;
                if (state.mode === "editingMode") {
                  addBtn = () => <div className="col-12 my-3  d-flex justify-content-between">
                    <button
                      className="btn btn-success "
                      onClick={kpiTableRowFactory}
                    >
                      + KPI Row
                    </button>
                  </div>
                }
                return <CardTable
                  addBtn={addBtn}
                  headerArr={headerArr}
                  rowArr={rowArr}
                />
              }
            )()
          }

          <div
            style={{
              marginTop: "40px",
            }}
          >
            <h4>
              Attachment List
            </h4>
          </div>
          {
            // attachment table
            (
              () => {

                const headerArr: any = [
                  "Name",
                ];

                const file_flag = ("examMode" === state.mode) || ("gradingModeTeacher" === state.mode)

                if (file_flag) {
                  // Action is only in editingMode so this col might not be rendered
                  headerArr.push("Action");
                }
                const attachmentTable = state?.calibration?.files;
                const rowArr = attachmentTable?.map((row: any, rowIndex: any) => {

                  let hasFailedFlag = false;
                  const rowProp2 = "hasFailedFlag";
                  if (row.hasOwnProperty(rowProp2)) { hasFailedFlag = row[rowProp2]; }

                  // used iife to reduce typos
                  const arr: any = [
                    () => {
                      // console.log(rowIndex,"----",loadingBarArr[rowIndex])
                      if
                        (showProgressArr[rowIndex])
                      // (showProgress && ((attachmentTable.length - 1) === rowIndex)) 
                      {
                        return (
                          <div
                            style={{
                              width: "100%",
                              height: "10px",
                              background: "white",
                              borderRadius: "10px",
                              border: "1px solid green",
                            }}
                          >

                            <div
                              style={{
                                width: loadingBarArr[rowIndex],
                                height: "10px",
                                borderRadius: "10px",
                                background: "#0c50a7",
                              }}
                            >

                            </div>
                          </div>
                        );
                      }
                      // the name can be changed in editingMode
                      let name = "";
                      const rowProp = "file_name";
                      if (row.hasOwnProperty(rowProp)) { name = row[rowProp]; }

                      return <div
                        style={{
                          cursor: hasFailedFlag ? "not-allowed" : "pointer",
                          color: hasFailedFlag ? "black" : "blue",
                          textDecoration: hasFailedFlag ? "none" : "underline",
                        }}

                        onClick={() => {
                          if (!hasFailedFlag) {
                            handleDocumentdriveid(row)
                          }
                        }}
                      >
                        <span  >{name}</span>
                        {
                          (hasFailedFlag) ?
                            <>
                              <br />
                              <span
                                style={{
                                  color: "red",
                                }}
                              >could not be uploaded. Please try again.</span>
                            </>
                            :
                            null
                        }
                      </div>
                    },
                  ];

                  if (file_flag) {
                    const delete_disabled = (userName !== row?.userName);
                    // Action is only in editingMode so this col might not be rendered
                    arr.push(() => {
                      return <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        {
                          (hasFailedFlag) ?
                            <button
                              // disabled={delete_disabled}
                              className='btn btn-success'
                              onClick={() => {
                                // console.log("should re-upload");
                                // console.log(row);
                                reuploadFileUpload2s3(row, rowIndex);
                              }}
                            >
                              {/* <i className="fa-solid fa-rotate-right"></i> */}
                              <i className="fas fa-rotate-right"></i>
                            </button>
                            :
                            null
                        }
                        <button
                          disabled={delete_disabled}
                          className='btn btn-danger'
                          onClick={() => {
                            Swal.fire({
                              icon: 'warning',
                              text: 'Are You Sure?',
                            }).then((v: any) => {
                              if (v?.isConfirmed) {
                                const updatedState = { ...state };
                                updatedState.calibration.files = attachmentTable?.filter((x_row: any, index: any) => rowIndex != index);
                                setShowProgressArr(showProgressArr.filter((x: any, i: number) => (i != rowIndex)))

                                setLoadingBarArr(loadingBarArr.filter((x: any, i: number) => (i != rowIndex)))

                                save2php(updatedState);
                              }
                            })
                          }}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>

                    });
                  }
                  return { serial_hash: row.driveid, arr }

                });
                let addBtn = null;
                if (file_flag) {
                  addBtn = () => <div className="col-12 my-3  d-flex justify-content-between">
                    <div                                        >
                      <input
                        accept="image/*,.xls.xlsx,.doc,.docx,.mp4,.pdf,.txt,.pptx,.ppt,.svg"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        multiple
                        name="filesuploaded"
                        onChange={handleFileUpload2browser}
                      />
                      <label
                        className="btn btn-success "
                        htmlFor="raised-button-file"
                      >
                        + Choose file
                      </label>
                      <p>*{"allowed extensions"}: <span style={{ fontWeight: 'bold' }}>jpeg, jpg, png, xlsx, xls, doc, docx, txt, mp4, pdf,ppt,pptx,svg</span> </p>

                    </div>
                  </div>
                }

                return <CardTable
                  addBtn={addBtn}
                  headerArr={headerArr}
                  rowArr={rowArr}
                />
              }
            )()
          }

          {/* ================================================== */}
          <div className="col-12 row">




            {state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
              <div className="col-12 row align-items-start align-content-start p-2 mx-auto">
                <label className="align-self-start">Feedback</label>
                <textarea
                  className="form-control align-self-stretch "
                  name=""
                  id=""
                  value={state.feedback}
                  rows={5}
                  disabled={
                    state.mode === "gradingModeStudent" ? true : false
                  }
                  onChange={(e) => {
                    let feedback = e.target.value;
                    let updatedState = { ...state, feedback }

                    onSaveQuestion(updatedState)

                    setState((prev: any) => ({ ...prev, feedback: feedback }));

                  }
                  }
                ></textarea>
              </div>
            ) : null}
          </div>





          <hr className="col-10" style={{ color: "white", backgroundColor: "white" }} />



          {state.mode === "editingMode" ? (
            <DifficultLevel
              difficult={state.difficult}
              cognitive={state.cognitive}
              onChangeDifficult={(difficult: any) => { setState((prev: any) => ({ ...prev, difficult: difficult })) }}
              onChangeCognitive={(cognitive: any) => { setState((prev: any) => ({ ...prev, cognitive: cognitive })) }}
            />

          ) : null}



          {(state.mode == "editingMode" || state.mode == "answeringMode") && <div className="text-center col-12">
            <button
              className="btn btn-warning btn-lg mt-5 rounded col-3"
              onClick={() => onSaveQuestion(state)}

            >
              {" "}
              Save
            </button>
          </div>}

        </div>
      </div>


      {openDocModal ?
        <DocumentModal open={openDocModal} setOpen={setOpenDocModal} file={selectedFile} section_id={""} >
          {
            (
              () => {
                if (selectedFile.driveid === undefined || selectedFile.driveid === null) {
                  return <div> File type is not valid</div>
                }

                const type = (selectedFile?.type?.split("/")[1] ?? "")?.toLowerCase();

                if (type === "pdf") {
                  return <iframe src={selectedFile.driveid} width="100%" height="100%">

                  </iframe>
                }

                if (type === "txt" || type == "plain" || type === "html" || videoTypes.includes(type)) {
                  return <iframe
                    src={selectedFile.driveid}
                    width="100%"
                    style={{ height: "85vh" }}
                    title="title"

                  ></iframe>

                }

                if (imgTypes.includes(type)) {
                  return <div style={{ height: "85vh", width: "100%" }}>
                    <img style={{ width: "100%" }} src={selectedFile.driveid} />
                  </div>

                }

                if (selectedFile.driveid.includes("amazonaws.com/")) {
                  docs[0].uri = selectedFile.driveid;
                  return <DocViewer documents={docs} requestHeaders={headers} prefetchMethod="GET" activeDocument={docs[0]} pluginRenderers={DocViewerRenderers} />

                }

                if (selectedFile.driveid.includes("https://") === false) {
                  return <iframe

                    src={`https://drive.google.com/file/d/${selectedFile.driveid}/preview`}
                    width="100%"
                    style={{ height: "85vh" }}
                    title="title"
                  ></iframe>

                }

                return <iframe
                  src={selectedFile.driveid}
                  width="100%"
                  style={{ height: "85vh" }}
                  title="title"

                ></iframe>
              }
            )()
          }

        </DocumentModal> : ""
      }

    </div >
  );

}
export default ProcessCardSingleState