import Cookies from "js-cookie";

let logoutTimer :any = null ;
const calculateRemainingTime = (remainingTime: number) => {
    const currentTime = new Date().getTime();
    const expirationTime = new Date(remainingTime).getTime();
    return expirationTime - currentTime;
}
const LogoutHandler = () => {
    Cookies.set("quiztoken" , "");
   location.reload();
    if(logoutTimer){
        clearTimeout(logoutTimer);
    }
}


export const setTokenExpiration = (decodedToken:any)=>{
   
    let remainingTime = calculateRemainingTime(decodedToken.exp * 1000);
   logoutTimer=  setTimeout(LogoutHandler, remainingTime)
}
