import React, { useEffect, useRef, useState } from "react";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { MapSecondsToTime, makeId, requestWithAxios } from "../../helpers/func";
import { OptionProps } from "./smallComponents/OrderOption";
import { handleCount, shuffleOps } from '../../helpers/validation';
import MatchOption from "./smallComponents/MatchOption";
import Swal from "sweetalert2";
import DifficultLevel from "./smallComponents/DifficultLevel";
import { BASE_URL } from "../../variables/api";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import axios from "axios";



// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent

const Match =(props:any) => { 
  const [state , setState] = useState<any>({
    id: makeId(10),
    show_objectives: false,
    shuffle_options: false,
    objectives: "",
    feedback: "",
    type: "MatchQComponent",
    mode: "",
    total_mark: "",
    score: "",
    score_mode: "partialMatch",
    header: "",
    required: true,
    options: [
      {
        id: "Gww36bw4fp",
        option: "",
      },

      {
        id: "riCGB7TiWz",
        option: "",
      },

    ],
    possible_answers: [
      {
        id: "Gww36bw4fn",
        option: "",
      },
      {
        id: "3wBfSgxAru",
        option: "",
      },

    ],
    correct_possible_answers: [
      {
        id: "Gww36bw4fn",
        option: ""
      },

      {
        id: "3wBfSgxAru",
        option: ""
      }
    ],
    difficult: "Basic Level",
    cognitive: "Remembering",
    alert: null
  });


  let count =  useRef<any>();
  const [spenttime, setSpentTime] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(null);
useEffect(()=>{
  let temp_state_options: any = [];
  let temp_options: any = [];
  let temp_possible_answers: any = [];
  let temp_correct_answers: any = [];

  if (props.quest != undefined) {
    let Question = {...props.quest};
   
    if (typeof Question.options  == "string") {
      temp_state_options = [...JSON.parse(Question.options )];
    } else {
     
      temp_state_options = [...Question.options ];
    }
    if (temp_state_options.length > 0 && temp_state_options[0]?.op1) {
      temp_state_options.forEach((option: any) => {
        // temp_options.push(option.op1);
        // temp_possible_answers.push(option.op2)
        // temp_correct_answers.push(option.op2)

        let tmp_op1 = option.op1;
        temp_options.push(tmp_op1);
        let tmp_op2 = option.op2;
        temp_possible_answers.push(tmp_op2)
        if (tmp_op2.key != tmp_op1.key) {
          const result = temp_state_options?.find((nested_op: any) => nested_op?.op2?.key === tmp_op1?.key);
          if (result) {
            tmp_op2 = result.op2;
          }
        }
        temp_correct_answers.push(tmp_op2)

      });
    } else {
      temp_options = [...temp_state_options]
      if(props.quest.correct_possible_answers){
        temp_correct_answers = [...props.quest.correct_possible_answers];
      }else {
        temp_correct_answers = []
      }
      if(props.quest.possible_answers){
        temp_possible_answers = [...props.quest.possible_answers];
      }else {
        temp_possible_answers = []
      }
     
    }
    Question.possible_answers = temp_possible_answers;
    Question.correct_possible_answers = temp_correct_answers;
    Question.options = [...temp_options];
    if (Question.mode == "examMode") {

      axios.get(`${BASE_URL}/student/getspenttime.php?studentexamquestion_id=${Question.id}`).then((response) => {
        setSpentTime(response.data.data);
        const intervalId = setInterval(() => {
          setSpentTime((prev: any) => ++prev);
          // props.onSaveQuestion({ ...state })
        }, 1000);
        setIntervalId(intervalId)
      }).catch((err) => {
        setSpentTime(0);
        const intervalId = setInterval(() => {
          setSpentTime((prev: any) => ++prev);
          // props.onSaveQuestion({ ...state })
        }, 1000);
        setIntervalId(intervalId)

      });
     
    }
    Swal.close()
    setState(Question)

  }else {
    setState((prev:any) => ({...prev , mode: "editingMode"}))
    Swal.close()
  }



},[]);

useEffect(() => {
  return () => {
      if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {
          clearInterval(intervalId)
          requestWithAxios(
              BASE_URL + "student/savespenttime.php",
              { spenttime: count.current.value, "studentexamquestion_id": state.id }
          );
      }
  }
}, [intervalId])

const onSaveQuestion =(updatedState: any) =>{



  let optionsRequired = false;
  let posAnsRequired = false;
  let require = handleCount(state.header);
  updatedState.options.forEach((o: any) => {
    if (optionsRequired === false) {
      if (handleCount(o.option) === true) {
        optionsRequired = true;
      }
    }
  });
  updatedState.possible_answers.forEach((o: any) => {
    if (posAnsRequired === false) {
      if (handleCount(o.option) === true) {
        posAnsRequired = true;
      }
    }
  });

  let optionscount = updatedState.options.length;
  let possibleAnscount = updatedState.possible_answers.length;


  if (require ) {
    Swal.fire({
      icon: 'error',
      text: ' Question Header is required',
    }).then(() => {
      
    })
  } else if (optionsRequired || posAnsRequired){
    Swal.fire({
      icon: 'error',
      text: ' Options are required',
    }).then(() => {
      
    })

  }
  else if ((state.total_mark == 0) || (state.total_mark == ".")) {
    Swal.fire({
      icon: 'error',
      text: ' Total mark is required',
    }).then(() => {
   
    })
  } else if (optionscount < 2 || possibleAnscount < 2) {
    Swal.fire({
      icon: 'error',
      text: "Options mustn't be less than two options",
    }).then(() => {
  
    })
  } else if (optionscount != possibleAnscount) {
    Swal.fire({
      icon: 'error',
      text: "Options must equal to Possible answers",
    }).then(() => {
    
    })
  }

  else {

    let final_options: any = [];

    updatedState.options.forEach((o: any, index: any) => {
      let findCorrectAnswer = updatedState.correct_possible_answers[index];
      let key = makeId(10)
      final_options.push(
        { op1: { id: o.id, key: key, option: o.option }, op2: { id: findCorrectAnswer.id, key: key, option: findCorrectAnswer.option } }
      );

    });
 
  
    if(updatedState.mode == "editingMode" || updatedState.mode == "answeringMode"){

      const newShuffle = shuffleOps(final_options);
      
      updatedState.options = newShuffle;
    }else if (updatedState.mode == "examMode"){
      if (state.studentexam_id !== undefined && state.studentexam_id != null) {
        let final_options: any = [];
        updatedState.options.forEach((o: any, index: any) => {
          let possible_answers = updatedState.possible_answers[index];
          final_options.push(
            { op1: { id: o.id, key: o.key, option: o.option }, op2: { id: possible_answers.id, key: possible_answers.key, option: possible_answers.option } }
          );
        });
      
      
      
      
         updatedState = {...state , options : final_options}
        requestWithAxios(
          BASE_URL + "student/saveq.php",
          updatedState
        );
      }

    }
   
    props.onSaveQuestion(updatedState)
  }
}




const addOptionHandler = () => {
  let id = makeId(10);
  let newOp = [
    ...state.options.concat({
      id: id,
      option: "",
      required: true
    }),
  ];


  let answerId = makeId(10);
  let newAnsweredOp = [
    ...state.possible_answers.concat({
      id: answerId,
      option: "",
      required: true
    }),
  ];
  let newPossibleAnsweredOp = [
    ...state.correct_possible_answers.concat({
      id: answerId,
      option: "",
      required: true

    }),
  ];
 
  setState((prev: any) => ({ ...prev, possible_answers: newAnsweredOp, correct_possible_answers: newPossibleAnsweredOp, options: newOp }));

};


const onOptionUpdate = (val: OptionProps) => {

  let option = state.options.find((x: any) => x.id === val.id);
  let options = state.options;
  let possible_answers = state.possible_answers;
  let correct_possible_answers = state.correct_possible_answers;

  if (option) {
    let index = state.options.indexOf(option);
    options[index] = {
      id: val.id,
      option: val.option,
    };
  }
  let PossibleAnswer = state.possible_answers.find((x: any) => x.id === val.id);
  if (PossibleAnswer) {
    let index = state.possible_answers.indexOf(PossibleAnswer);
    possible_answers[index] = {
      id: val.id,
      option: val.option,
    };

  }



  let correctPossibleAnswer = state.correct_possible_answers.find((x: any) => x.id === val.id);
  if (correctPossibleAnswer) {
    let index = state.correct_possible_answers.indexOf(correctPossibleAnswer);
    correct_possible_answers[index] = {
      id: val.id,
      option: val.option,

    };

  }
 
  setState((prev: any) => ({ ...prev, options: options, correct_possible_answers: correct_possible_answers, possible_answers: possible_answers }));

};

const onDeleteOption = (id: any) => {
  let options = state.options;
  let opIndex = state.possible_answers.findIndex((op: any) => op.id == id)

  options.splice(opIndex, 1);
  let newAnsweredOp = [...state.possible_answers.filter((op: any) => op.id !== id)];
  let correct_possible_answers = [...state.correct_possible_answers.filter((op: any) => op.id !== id)];

  setState((prev:any)=>({ ...prev,  options: options, possible_answers: newAnsweredOp, correct_possible_answers: correct_possible_answers }));


};


const toggleHandler = () => {
  if (state.mode === "editingMode") {
    setState((prev:any)=>({...prev, mode: "answeringMode" }));
  } else {
  
    setState((prev:any)=>({...prev, mode: "editingMode" }));
  }
};


const onDragOps = (e: any, index: any) => {

  e.dataTransfer.setData("id", index);
};
const onDropOps = (source: any, destination: any) => {
  if (state.mode == "editingMode" || state.mode == "examMode") {

    var comingele = source

    let data = [...state.options];

    if (comingele) {

      data.splice(destination, 0, data.splice(source, 1)[0])
      let options = [...data]
    
      setState((prev:any)=>({...prev, options: options}));
    }
  } else if (state.mode == "answeringMode") {
    var comingele = source

    let data = [...state.options];
    if (comingele) {
      data.splice(destination, 0, data.splice(source, 1)[0])
     
      setState((prev:any)=>({...prev, options: data}));

    }
  }
};
const onDragPossibleAnswer = (e: any, index: any) => {

  e.dataTransfer.setData("id", index);
};

const onDropPossibleAnswer = (source: any, destination: any) => {
 
   if (state.mode == "editingMode" || state.mode == "examMode") {

    var comingele = source;

    let data = [...state.possible_answers];

    data.splice(destination, 0, data.splice(source, 1)[0])
  
    setState((prev:any)=>({...prev, possible_answers: data}));

    if (state.mode == "examMode") {
      let possible_answers = data
      let updatedState = { ...state, possible_answers }

      onSaveQuestion(updatedState)
    }
  } else if (state.mode == "answeringMode") {


    let data = [...state.correct_possible_answers];

    data.splice(destination, 0, data.splice(source, 1)[0])
    setState((prev:any)=>({...prev, correct_possible_answers: data}));
  }
};
const onDragOver = (e: any) => {

  e.preventDefault();
};
const calculateScore = () =>{
  let numberOfQuestions = state.possible_answers.length;
  let optionScore = +state.total_mark / numberOfQuestions;
  let studentScore = 0;
  if (state.score_mode == "partialMatch") {
    for (let index = 0; index < state.possible_answers.length; index++) {
      if (state.possible_answers[index].id == state.correct_possible_answers[index].id) {
        studentScore += optionScore;
      }
    }
  } else if (state.score_mode == "exactMatch") {
    for (let index = 0; index < state.options.length; index++) {
      if (state.possible_answers[index].id == state.correct_possible_answers[index].id) {
        studentScore += optionScore;
      }
    }
    if (studentScore < +state.total_mark) studentScore = 0;
  }
 
  setState((prev:any)=>({...prev,score: +studentScore.toFixed(2)}));
}





let opsEditingMode = state.possible_answers?.map((op: any, index: any) => {
  return (
    <div
      key={op.id}
      className="col-12 m-2 row align-items-baseline"
    >
      <div className="col-6">
        <MatchOption
          correctAnswer={false}
          style={{ backgroundColor: "white" }}
          key={state.options[index]?.id}
          option={state.options[index]?.option}
          id={state.options[index]?.id}
          index={index + 1}
          mode={state.mode}
          onOptionUpdate={onOptionUpdate}
          onDeleteOption={onDeleteOption}
          required={true}
          delete={false}
        />
      </div>


      <Draggable draggableId={`draggable-${op.id}`} index={index} key={op.id}>
        {(provieded, snapshot) => (
          <div
            ref={provieded.innerRef}
            {...provieded.draggableProps}
            {...provieded.dragHandleProps}
            key={op.id}
            id={op.id}
            className="col-6  row align-items-baseline"

          >
            <MatchOption
              correctAnswer={false}
              style={{ backgroundColor: "white" }}
              key={op.id}
              option={op.option}
              id={op.id}
              index={index + 1}
              mode={state.mode}
              onOptionUpdate={onOptionUpdate}
              onDeleteOption={onDeleteOption}
              required={true}
              delete={true}
            />
          </div>

        )}
      </Draggable>

    </div>
  );
});

let opsAnsweringMode = state.correct_possible_answers?.map((op: any, index: any) => {
  return (
    <div

      key={op.id}
      className="col-12 m-2 row align-items-baseline"

    >
      <div className="col-6">


        <MatchOption
          correctAnswer={false}

          style={{ backgroundColor: "white" }}
          key={state.options[index]?.id}
          option={state.options[index]?.option}
          id={state.options[index]?.id}
          index={index + 1}
          mode={state.mode}

          onOptionUpdate={onOptionUpdate}
          onDeleteOption={onDeleteOption}
          required={true}
          delete={false}

        />
      </div>

      <Draggable draggableId={`draggable-${op.id}`} index={index} key={op.id}>
        {(provieded, snapshot) => (
          <div
            ref={provieded.innerRef}
            {...provieded.draggableProps}
            {...provieded.dragHandleProps}
            key={op.id}
            id={op.id}
            className="col-6  row align-items-baseline"

          >

            <MatchOption
              correctAnswer={false}

              style={{ backgroundColor: "white" }}
              key={op.id}
              option={op.option}
              id={op.id}
              index={index + 1}
              mode={state.mode}

              onOptionUpdate={onOptionUpdate}
              onDeleteOption={onDeleteOption}
              required={true}
              delete={false}
            />
          </div>
        )}
      </Draggable>
    </div>
  );
});




let opsGradingMode = state.possible_answers?.map((op: any, index: any) => {


  return (
    <div
      key={op.id}
      className="col-12 m-2 row align-items-baseline"

    >


      {state.options[index].key == state.possible_answers[index].key ?
        (

          <React.Fragment>
            <div className="col-6">


              <MatchOption
                correctAnswer={false}

                style={{ backgroundColor: "white" }}
                key={state.options[index]?.id}
                option={state.options[index]?.option}
                id={state.options[index]?.id}
                index={index + 1}
                mode={state.mode}

                onOptionUpdate={onOptionUpdate}
                onDeleteOption={onDeleteOption}
                required={true}
                delete={false}

              />
            </div>
            <div className="col-6">

              <MatchOption
                correctAnswer={false}

                style={{
                  backgroundColor: "#9df3b1 ",
                  border: "4px solid #098434 ", textColor: "white !important"
                }}
                key={op.id}
                option={op.option}
                id={op.id}
                index={index + 1}
                mode={state.mode}
                onOptionUpdate={onOptionUpdate}
                onDeleteOption={onDeleteOption}
                required={true}
                delete={false}
              />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="col-6">


              <MatchOption
                correctAnswer={false}

                style={{ backgroundColor: "white" }}
                key={state.options[index]?.id}
                option={state.options[index]?.option}
                id={state.options[index]?.id}
                index={index + 1}
                mode={state.mode}

                onOptionUpdate={onOptionUpdate}
                onDeleteOption={onDeleteOption}
                required={true}
                delete={false}

              />
            </div>

            <div className="col">
              <MatchOption
                correctAnswer={false}

                style={{ backgroundColor: "#ffe1e2", textColor: "white" }}
                key={op.id}
                option={op.option}
                id={op.id}
                index={index + 1}
                mode={state.mode}
                onOptionUpdate={onOptionUpdate}
                onDeleteOption={onDeleteOption}
                required={true}
                delete={false}
              />
            </div>
            <div className="col-auto ">Correct answer : <i className="fas fa-arrow-right"></i></div>
            <div className="col">


              <MatchOption
                correctAnswer={true}
                style={{
                  backgroundColor: "#9df3b1 ",
                  border: "4px solid #098434 ", textColor: "white"
                }}
                key={state.correct_possible_answers.find((answer: any) => answer.key == state.options[index].key)?.id}
                option={state.correct_possible_answers.find((answer: any) => answer.key == state.options[index].key)?.option}
                id={state.correct_possible_answers.find((answer: any) => answer.key == state.options[index].key)?.id}
                index={index + 1}
                mode={state.mode}
                onOptionUpdate={onOptionUpdate}
                onDeleteOption={onDeleteOption}
                required={true}
                delete={false}

              />
            </div>


          </React.Fragment>

        )
      }

    </div>
  );
});

return (
  // <div className="mx-5">
  <div className="">
    
    <div className="row rounded my-3 p-0 ll shadow bg-question">
      {state.mode == "examMode" && (
        <div className="row col-12">
          <div className="  pt-4 pl-4 col-4" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}> Q {props.index}
          </div>
          <div className="  p-3 col-6 d-flex justify-content-end" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}>
          <input type="hidden" ref={count}  value={spenttime}/>
            <div>
              Spent time : <label id="minutes">{MapSecondsToTime(Math.floor(spenttime / 60))}</label>:<label id="seconds">{spenttime > 0 ? MapSecondsToTime(+spenttime % 60) : "00"}</label>
            </div>
          </div>
        </div>
      )}
      <div className="col-12 row align-items-center mx-auto  bg-question rounded pl-3 pr-3 text-white">
        <div className="col-12 row justify-content-between mt-3">
          {state.mode == "editingMode" ? (<h3>Question Setup</h3>) : null}
          {state.mode == "answeringMode" ? (<h3>Correct answer setup</h3>) : null}
          <div className="d-flex flex-column">
            {state.mode === "editingMode" ||
              state.mode === "answeringMode" ? (
              <button
                className="btn btn-warning text-white "
                onClick={toggleHandler}
              >
                {state.mode == "editingMode" ? "Go to Answer mode" : "Go to Question setup mode"}
              </button>
            ) : null}


            <div className="form-group d-flex align-items-center my-3">
              {state.mode === "editingMode" ||

                state.mode === "answeringMode" ? (
                <React.Fragment>

                  <span className="col-auto">Total mark :  </span>
                  <input
                    type="text"

                    className="col form-control"
                    name=""
                    id=""
                    value={state.total_mark}
                    onChange={(e) => {
                      if ((typeof +e.target.value == "number" && +e.target.value > 0) || e.target.value == "" || e.target.value == ".") {
                       
                        let total = e.target.value
  setState((prev:any)=>({...prev,total_mark: total }));

                      }
                    }
                    }
                  />
                </React.Fragment>
              ) :

                state.mode === "gradingModeTeacher" ||
                  state.mode === "gradingModeStudent" ? (
                  <React.Fragment>

                    <span className="col-auto">Total mark : {state.total_mark} </span><span className="border-left " >   Score  : {state.score} </span>


                  </React.Fragment>
                ) : state.mode === "examMode" ? (<React.Fragment>

                  <span className="col-auto">Total mark   :  </span>

                  <span>{state.total_mark}</span>
                </React.Fragment>) : null}
            </div>
          </div>
        </div>



{
  ("editingMode" != state?.mode) ? null :
    <div className="col-12 mb-5 row justify-content-center align-items-center">
      <div
        className="bg-white w-100 text-dark rounded align-items-center p-3"
      >
        Matching 
      </div>
    </div>

}







        <div className="col-12 mb-5 row justify-content-center align-items-center">
          {state.mode === "editingMode" ? (
            <div className="col-12 w-100 row justify-content-center align-items-center">
              <RichBox
                onChange={(v: any) => {setState((prev:any)=>({...prev,header: v }));}}

                defaultValue={state.header}
                height={"10px"}
              ></RichBox>
            </div>
          ) : state.mode === "examMode" ||
            state.mode === "answeringMode" ||
            state.mode === "gradingModeTeacher" ||
            state.mode === "gradingModeStudent" ? (
            <div

              className="bg-white w-100 text-dark rounded align-items-center p-3"
              dangerouslySetInnerHTML={{ __html: state.header }}
            ></div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="col-12 row">


          <h5 className="col-6">Options :</h5>
          <h5 className="col-6">Possible Answers : {state.mode != "editingMode" ? "You should drag  option and drop it in another option to swap between them" : ""}</h5>

          {state.mode === "editingMode" || state.mode === "examMode" ?
            <DragDropContext
              onDragEnd={(param) => {
                onDropPossibleAnswer(param.source.index, param.destination?.index);
              }}>
              <Droppable droppableId="drop1">
                {(provieded, snapshot) => {
                  return (
                    <div className="col-12" ref={provieded.innerRef} {...provieded.droppableProps}>
                      {opsEditingMode}
                      {provieded.placeholder}

                    </div>
                  )
                }}
              </Droppable>
            </DragDropContext>
            : state.mode === "answeringMode" ? <DragDropContext
              onDragEnd={(param) => {
                onDropPossibleAnswer(param.source.index, param.destination?.index);
              }}>
              <Droppable droppableId="drop1">
                {(provieded, snapshot) => {
                  return (
                    <div className="col-12" ref={provieded.innerRef} {...provieded.droppableProps}>
                      {opsAnsweringMode}
                      {provieded.placeholder}

                    </div>
                  )
                }}
              </Droppable>
            </DragDropContext> :
              state.mode === "gradingModeTeacher" || state.mode === "gradingModeStudent" ? opsGradingMode : null
          }
          {/* state.mode === "answeringMode" ?opsAnsweringMode: state.mode === "examMode" ? opsExamMode : opsGradingMode */}

          {/* <h5 className="col-9"> Possible Answers :</h5>


            {state.mode === "answeringMode" ? correct_possible_answers : null}
            {state.mode === "editingMode" ? possible_answers : null}
            {state.mode === "examMode" ? possible_answers : null}
            {state.mode === "gradingModeTeacher" ? gradingOptions : null} */}

        </div>



        {state.mode === "editingMode" ? (
          <div className="col-12 my-3  d-flex justify-content-between">
            <button
              className="btn btn-success "
              onClick={addOptionHandler}
            >
              + Options
            </button>
            {/* <button
              className="btn btn-success "
              onClick={addPossibleAnswerHandler}
            >
              + Possible Answers
            </button> */}

          </div>
        ) : null}
        <hr className="col-10" style={{ color: "white", backgroundColor: "white" }} />

        {state.mode === "editingMode" ? (
          <React.Fragment>


            <div className="col-12"> Scoring Mode :</div>
            <div className="col-12 row my-3 ml-5">
              <input
                type="radio"
                name="match"
                className="mr-2"
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                id="exactMatch"
                value="exactMatch"
                checked={state.score_mode == "exactMatch" ? true : false}
                onChange={(e) =>
                  {
                 let score_mode = e.target.value
  
                    setState((prev:any)=>({...prev,score_mode: score_mode }));
                  }
                }
              />
              <label htmlFor="exactMatch" style={{ cursor: "pointer" }} className="mr-5">
                Exact match
              </label>
              <input
                type="radio"
                name="match"
                value="partialMatch"
                checked={state.score_mode == "partialMatch" ? true : false}

                className="mr-2"
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                id="partialMatch"
                onChange={(e) =>
                  {
                    let score_mode = e.target.value
     
                       setState((prev:any)=>({...prev,score_mode: score_mode }));
                     }
                }
              />
              <label htmlFor="partialMatch" style={{ cursor: "pointer" }}>
                Partial match
              </label>
            </div>
          </React.Fragment>
        ) : null}
        {state.mode === "editingMode" ? (
          <DifficultLevel
            difficult={state.difficult}
            cognitive={state.cognitive}
            onChangeDifficult={(difficult: any) => { setState((prev:any)=>({...prev , difficult: difficult })) }}
            onChangeCognitive={(cognitive: any) => {  setState((prev:any)=>({...prev , cognitive: cognitive }))}}
          ></DifficultLevel>

        ) : null}




        {state.mode === "gradingModeTeacher" ||
          state.mode === "gradingModeStudent" ? (
          <div className="col-12 ml-4 row align-items-start align-content-start p-2">
            <label className="align-self-start">Feedback</label>
            <textarea
              className="form-control align-self-stretch "
              name=""
              id=""
              value={state.feedback}
              rows={5}
              disabled={
                state.mode === "gradingModeStudent" ? true : false
              }
              onChange={(e) => {
                let feedback = e.target.value;
                let updatedState = { ...state, feedback }
                onSaveQuestion(updatedState)
                setState((prev:any)=>({...prev , feedback: feedback }))
              }
              }
            ></textarea>
          </div>
        ) : null}

        {(state.mode == "editingMode" || state.mode == "answeringMode") && <div className="text-center col-12">
          <button
            className="btn btn-warning btn-lg rounded col-3 mb-3 mt-3"
            onClick={() => onSaveQuestion(state)}

          >
            {" "}
            Save
          </button>
        </div>}
      </div>
    </div>
  </div>
);
  
}
 export default Match