import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
import { ApplicationState } from "../../store";
import { actionCreators as UserActionCreator } from "../../store/User";
import $ from "jquery";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { handleCount } from '../../helpers/validation';

import { makeId } from "../../helpers/func";
import Swal from "sweetalert2";
// import { isNullOrUndefined } from '../../helpers/func';
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  GetUser: () => dispatch(UserActionCreator.GET_USER()),
  SetMenu: (menu: any) => dispatch(UserActionCreator.SET_USER_MENU(menu)),
  Logout: () => dispatch(UserActionCreator.CLEAR_USER_LOGOUT()),
});




class Label {
  id = makeId(10);
  value = "";
  top = -1;
  left = -1;
  onDelete: any;
  onDrop: any;
  onDrag = (e: any, index: any) => {
    e.dataTransfer.setData("id", index);
  };
  onDropFunc = (e: any) => {
    var optionId = e.dataTransfer.getData("id");
    this.onDrop(optionId, this.id);
  };
  setValue = (e: any) => {
    this.value = e.target.value;
  }
  Delete = () => {
    if (this.onDelete) this.onDelete(this.id);
  }
  setPos = (top: number, left: number) => {
    this.top = top;
    this.left = left;
  }
  onDragOver = (e: any) => {

    e.preventDefault();
  };

  render = (mode = "editingMode", index: any) => {
    if (this.top == -1 && this.left == -1)
      return (
        <div onDragStart={(e) => { this.onDrag(e, this.id) }} className="col-3" draggable={true} style={{ cursor: "pointer" }} onDragOver={this.onDragOver} >
          {mode == "editingMode" ? (<label >  {index} - </label>) : null}
          <input type="text" onChange={this.setValue} disabled id={"id" + this.id} />
          {mode == "editingMode" ? (<span style={{ cursor: "pointer" }} onClick={this.Delete}> X </span>) : null}
        </div>
      );
    else {
      return (
        <div onDragStart={(e) => { this.onDrag(e, this.id) }} draggable={true} style={{ cursor: "pointer", position: "absolute", top: this.top, left: this.left }} onDragOver={this.onDragOver} onDrop={this.onDropFunc}>
          {mode == "editingMode" ? (<label >  {index} - </label>) : null}

          <input type="text" onChange={this.setValue} disabled id={"id" + this.id} />
          {mode == "editingMode" ? (<span style={{ cursor: "pointer" }} onClick={this.Delete}> X </span>) : null}    </div>
      );
    }
  }
  constructor(i: string, value = "", top = -1, left = -1, onDelete = (id: any) => { }, onDrop = (id: any, i: any) => { }) {
    this.id = i;
    this.value = value; this.top = top; this.left = left; this.onDelete = onDelete;
    this.onDrop = onDrop;
  }
}


//option 

class Option {
  id = "";
  value = "";
  top = -1;
  left = -1;
  onDelete: any;
  required = true;
  onDrag = (e: any, id: any) => {
    e.dataTransfer.setData("id", id);
  };
  setValue = (e: any) => {

    this.value = e.target.value;
  }
  Delete = () => {
    if (this.onDelete) this.onDelete(this.id);
  }
  setPos = (top: number, left: number) => {
    this.top = top;
    this.left = left;
  }
  render = (mode = "editingMode", index: any, iscorrect: any) => {
    if (this.top == -1 && this.left == -1)
      return (
        <div onDragStart={(e) => { this.onDrag(e, this.id) }}

          className="col-3 mb-3" draggable={true} style={{ cursor: "pointer" }}  >
          {mode == "editingMode" ? (<label >  {index} - </label>) : null}

          <input type="text" defaultValue={this.value} onChange={this.setValue} id={"id" + this.id} readOnly={mode != "editingMode" ? true : false} />
          {mode == "editingMode" ? (
            <span style={{ cursor: "pointer" }} onClick={this.Delete}> X </span>
          ) : null}

        </div>
      );
    else {

      return (

        <div onDragStart={(e) => { this.onDrag(e, this.id) }}

          draggable={true} style={{ cursor: "pointer", position: "absolute", top: this.top, left: this.left }}>
          {mode == "editingMode" ? (<label >  {index} - </label>) : null}




          <input className={`${iscorrect && mode == "gradingModeTeacher" ? 'isCorrectInput' : iscorrect && mode == "gradingModeTeacher" ? 'isWrongtInput' : ""}`} type="text" defaultValue={this.value} onChange={this.setValue} id={"id" + this.id} readOnly={mode != "editingMode" ? true : false} />

          {mode != "gradingModeTeacher" ? (<span style={{ cursor: "pointer" }} onClick={this.Delete}> X </span>) : null}
        </div>
      );
    }
  }
  constructor(i: string, value = "", top = -1, left = -1, onDelete = (id: any) => { }) {
    this.id = i;
    this.value = value; this.top = top; this.left = left; this.onDelete = onDelete;
  }
}

// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent
class LabelQ extends Component<any, any> {

  constructor(props: any) {
    super(props)


    if (this.props.quest != undefined) {
      this.state = this.props.quest;
    }
    this.onConfirm = this.onConfirm.bind(this)
    this.generateLabels()
    this.generateOptions()
  }
  onConfirm(e: any) {
    this.setState({ alert: null })
  }
  // state = obj;
  onSaveQuestion() {
    let optionsrequired = false;

    let require = handleCount(this.state.header);
    this.state.options.forEach((o: any) => {
      if (optionsrequired === false) {
        if (handleCount(o.value) === true) {
          optionsrequired = true;
        }
      }

    });
    if (require || optionsrequired) {
      Swal.fire({
        icon: 'error',
        text: ' Question header and quetions options  are required',
      }).then(() => {
        this.onConfirm(null)
      })
    } else if (this.state.image == "") {
      Swal.fire({
        icon: 'error',
        text: '   You have to upload image',
      }).then(() => {
        this.onConfirm(null)
      })
    } else if (this.state.totalMark.length == 0) {
      Swal.fire({
        icon: 'error',
        text: '  Total mark is required ',
      }).then(() => {
        this.onConfirm(null)
      })
    } else {
      this.props.onSaveQuestion(this.state)
    }
  }

  onDropOption = (optionId: string, labelId: string) => {
    if (this.state.mode == "answeringMode" || this.state.mode == "examMode") {

      let data = this.state.options;
      let option: any = this.state.options.find(o => o.id == optionId);
      let label = this.state.labels.find(o => o.id == labelId);
      option?.setPos(label?.top ?? 0, label?.left ?? 0);
      this.state.correctOptions.push(option);
      this.setState({ options: data })
    }
  }
  onDeleteLabel = (id: any) => {
    if (this.state.mode == "editingMode") {
      let newOp = [...this.state.labels.filter((op) => op.id !== id)];
      this.setState({ labels: newOp });
    }
  };
  onDeleteOption = (id: any) => {
    if (this.state.mode == "editingMode") {

      let newOp = [...this.state.options.filter((op) => op.id !== id)];
      this.setState({ options: newOp });
    } else if (this.state.mode == "answeringMode" || this.state.mode == "examMode") {
      let option = this.state.options.find(o => o.id == id);
      option?.setPos(-1, -1);
      let newop = this.state.options;
      this.setState({ options: newop })
    }
  };

  state = {
    id: makeId(10),
    showObjectives: false,
    shuffleOptions: false,
    objectives: "",
    feedback: "",
    mode: "editingMode",
    type: "LabelQComponent",
    totalMark: "",
    score: "0",
    scoreMode: "partialMatch",
    image: "",
    required: true,
    alert: null,
    difficult: "Basic Level",
    cognitive: "Remembering",
    header:
      '<p><span style="font-size: 14px;"><strong></strong></span></span></span></p>',
    labels: [new Label(makeId(10), "", -1, -1, this.onDeleteLabel, this.onDropOption)],
    options: [new Option(makeId(10), "", -1, -1, this.onDeleteOption)],
    correctOptions: [new Option(makeId(10), "", -500, -500, this.onDeleteOption)]
  };

  componentDidMount() {
    $("#preloader").hide();

  }
  // setHeader = (contents: any) => {
  //   this.header = contents;
  // };

  addLabelHandler = () => {
    let newOp = this.state.labels;
    newOp.push(new Label(makeId(10), "", -1, -1, this.onDeleteLabel, this.onDropOption));
    this.setState({ labels: newOp });
  };
  addOptionHandler = () => {
    let newOp = this.state.options;
    newOp.push(new Option(makeId(10), "", -1, -1, this.onDeleteOption));
    this.setState({ options: newOp });
  };

  toggleHandler = () => {
    if (this.state.mode === "editingMode") {
      this.setState({ mode: "answeringMode" });
    } else {
      this.setState({ mode: "editingMode" });
    }
  };

  onHandleCount = () => {
    let optionsrequired = false;
    let require = handleCount(this.state.header);
    this.state.options.forEach((o: any) => {
      if (optionsrequired === false) {

        if (o.required === true) {
          optionsrequired = true;
        }
      }
    });

    if (require === false && this.state.image != "") {
      this.setState({ required: false });
    } else {

      this.setState({ required: true });

    }
  }

  openAddFileInputHandler = () => {
    const fileInput = document.getElementById("addFile") as HTMLInputElement;
    fileInput.click();
  };

  addFileHandler = (e: any) => {
    const file: any = e.target.files[0];


    if (file && file.size > 0) {
      var reader: any = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (reader.result.split(":")[1].split("/")[0] === "image") {
          this.setState({ image: reader.result, selectedFile: file });
          this.onHandleCount()
        }
      };
    }
  };


  offset = (el: any) => {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop - el.offsetTop, left: rect.left + scrollLeft - el.offsetLeft }
  }


  onDrop = (e: any) => {
    if (this.state.mode == "editingMode") {

      var labelId = e.dataTransfer.getData("id");
      let lbls = this.state.labels;
      let lbl = lbls.find(l => l.id == labelId);




      // example use

      var rect = this.offset(e.target);

      if (lbl)
        lbl.setPos(e.pageY - rect.top, e.pageX - rect.left);




      let data = [...this.state.labels];

      //let temp = data[comingele];


      this.setState({ labels: data });
    } else if (this.state.mode == "answeringMode") {
      var labelId = e.dataTransfer.getData("id");
      let lbls = this.state.options;
      let lbl = lbls.find(l => l.id == labelId);
      var rect = this.offset(e.target);

      if (lbl)
        lbl.setPos(e.pageY - rect.top, e.pageX - rect.left);





      let data = [...this.state.labels];

      //let temp = data[comingele];


      this.setState({ ooptions: data });

    }
  };
  onDragOver = (e: any) => {

    e.preventDefault();
  };

  generateLabels = () => {
    let labels = this.state.labels;
    labels = labels.map((label) => {
      return (new Label(label.id, "", label.top, label.left, this.onDeleteLabel, this.onDropOption))
    })
    this.setState({ labels: labels })
  }
  generateOptions = () => {
    let options = this.state.options;

    options = options.map((option) => {
      return (
        this.state.mode == "examMode" ? new Option(option.id, option.value, -1, -1, this.onDeleteOption) : new Option(option.id, option.value, option.top, option.left, this.onDeleteOption))
    })
    let correctOptions = this.state.correctOptions;

    correctOptions = correctOptions.map((option) => {
      return (this.state.mode == "examMode" ? new Option(option.id, "", -1, -1, this.onDeleteOption) : new Option(option.id, "", option.top, option.left, this.onDeleteOption))
    })
    this.setState({ correctOptions: correctOptions, options: options })
  }


  calculateScore = () => {
    let numberOfQuestions = this.state.labels.length;
    let optionScore = +this.state.totalMark / numberOfQuestions;
    let studentScore = 0;
    if (this.state.scoreMode == "partialMatch") {
      for (let c = 0; c < this.state.correctOptions.length; c++) {
        for (let op = 0; op < this.state.options.length; op++) {
          if (this.state.options[op].id == this.state.correctOptions[c].id && this.state.options[op].top == this.state.correctOptions[c].top && this.state.options[op].left == this.state.correctOptions[c].left) {

            studentScore += optionScore;
          }

        }

      }
    } else if (this.state.scoreMode == "exactMatch") {
      for (let index = 0; index < this.state.options.length; index++) {
        if (this.state.options[index].id == this.state.correctOptions[index].id) {
          studentScore += optionScore;
        }
      }
      if (studentScore < +this.state.totalMark) studentScore = 0;
    }

    return studentScore;
  }
  checkCorrect = (id: any) => {
    let option = this.state.options.find(op => op.id == id);
    let iscorrect = false;
    for (let c = 0; c < this.state.correctOptions.length; c++) {

      if (option?.id == this.state.correctOptions[c].id && option?.top == this.state.correctOptions[c].top && option?.left == this.state.correctOptions[c].left) {

        iscorrect = true
      }



    }


    return iscorrect;
  }
  render() {
    let studentScore = this.calculateScore()
    let SettedLabels = this.state.labels.map((op, index) => {
      if (op.top !== -1)
        return (
          <React.Fragment key={op.id}>

            {op.render(this.state.mode, index + 1)}
          </React.Fragment>
        );
    });
    let NotSettedLabels = this.state.labels.map((op, index) => {
      if (op.top === -1)
        return (
          <React.Fragment key={op.id}>

            {op.render(this.state.mode, index + 1)}
          </React.Fragment>
        );
    });

    let SettedOption = this.state.options.map((op, index) => {
      let iscorrect = this.checkCorrect(op.id)
      if (op.top !== -1)
        return (
          <React.Fragment key={op.id}>

            {op.render(this.state.mode, index + 1, iscorrect)}
          </React.Fragment>
        );
    });
    let NotSettedOption = this.state.options.map((op, index) => {
      let iscorrect = this.checkCorrect(op.id)
      if (op.top === -1)
        return (
          <React.Fragment key={op.id}>

            {op.render(this.state.mode, index + 1, iscorrect)}
          </React.Fragment>
        );
    });

    return (
      // <div className="mx-5">
      <div className="">

        {this.state.alert}
        <div className="row rounded my-3 p-0 ll shadow ">
          {this.state.mode == "examMode" && (<div style={{ color: this.props.color || "#fff", fontSize:"28px", paddingLeft: "10px", fontWeight: "bold" }}> Q {this.props.index} <br></br> </div>)}
          <div className="col-12 row align-items-center mx-auto  bg-question rounded p-3 text-white">
            <div className="col-12 row justify-content-between">
              {this.state.mode == "editingMode" ? (<h3>Question Setup</h3>) : null}
              {this.state.mode == "answeringMode" ? (<h3>Correct answer setup</h3>) : null}
              <div className="d-flex flex-column">
                {this.state.mode === "editingMode" ||
                  this.state.mode === "answeringMode" ? (
                  <button
                    className="btn btn-warning text-white "
                    onClick={this.toggleHandler}
                  >
                    {this.state.mode == "editingMode" ? "Go to Answer mode" : "Go to Question setup mode"}
                  </button>
                ) : null}
                <div className="form-group d-flex align-items-center my-3">
                  {this.state.mode === "editingMode" ||

                    this.state.mode === "answeringMode" ? (
                    <React.Fragment>

                      <span className="col-auto">Total mark :  </span>
                      <input
                        type="number"
                        min={0}
                        className="col form-control"
                        name=""
                        id=""
                        value={this.state.totalMark}
                        onChange={(e) =>
                          this.setState({ totalMark: e.target.value })
                        }
                      />
                    </React.Fragment>
                  ) :

                    this.state.mode === "gradingModeTeacher" ||
                      this.state.mode === "gradingModeStudent" ? (
                      <React.Fragment>

                        <span className="col-auto">Score  :  </span>

                        <span>{studentScore + "/" + this.state.totalMark}</span>
                      </React.Fragment>
                    ) : this.state.mode === "examMode" ? (<React.Fragment>

                      <span className="col-auto">Total mark   :  </span>

                      <span>{this.state.totalMark}</span>
                    </React.Fragment>) : null}
                </div>
              </div>
            </div>
            <div className="col-12 mb-5 row justify-content-center align-items-center">
              {this.state.mode === "editingMode" ? (
                <div className="col-12 w-100 row justify-content-center align-items-center">
                  <RichBox
                    onChange={(v: any) => { this.setState({ header: v }); }}
                    onChangeTxt={(a: any) => { this.setState({ headertxt: a }); }}
                    defaultValue={this.state.header}
                    height={"10px"}
                  ></RichBox>
                </div>
              ) : this.state.mode === "examMode" ||
                this.state.mode === "answeringMode" ||
                this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (

                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: this.state.header }}
                ></div>


              ) : (
                <div></div>
              )}
            </div>
            <div className="col-12  text-white">
              <input
                type="file"
                id="addFile"
                hidden={true}
                onChange={this.addFileHandler}
              />

              <div className="col-12 ">



                {this.state.mode == "editingMode" ? (
                  <button
                    type="button"
                    disabled={this.props.loading}
                    style={{ border: "none", background: "inherit" }}
                    className={`col-12  text-white  `}
                    onClick={this.openAddFileInputHandler}
                  >
                    <i className="fas fa-plus-circle fa-2x"></i> Upload Image
                  </button>

                ) :
                  <button
                    type="button"
                    disabled={true}
                    style={{ border: "none", background: "inherit" }}
                    className={`col-12  text-white `}

                    onClick={this.openAddFileInputHandler}
                  >
                    <i className="fas fa-plus-circle fa-2x "></i>
                  </button>
                }


                {this.state.image ? (
                  <div className=" relative">
                    {this.state.mode == "editingMode" ? (

                      <img onDragOver={this.onDragOver} onDrop={(e) => { this.onDrop(e) }}
                        src={this.state.image} className="relative " height="100%" alt=""></img>
                    ) :
                      <img src={this.state.image} className="relative " height="100%" alt=""></img>
                    }
                    {SettedLabels}
                    {SettedOption}
                    <div className="row">

                      {this.state.mode == "editingMode" ? (<h5 className="col-12 mt-5">Labels :</h5>) : ""}

                      {this.state.mode == "editingMode" ? NotSettedLabels : null}
                      <div className="col-12">
                        {this.state.mode == "editingMode" ? (
                          <button
                            className="btn btn-success col12 "
                            onClick={this.addLabelHandler}
                          >
                            + Label
                          </button>) : null}
                      </div>
                      <h5 className="col-12 mt-5">Options :</h5>

                      {NotSettedOption}
                      <div className="col-12">
                        {this.state.mode == "editingMode" ? (
                          <button
                            className="btn btn-success col12 "
                            onClick={this.addOptionHandler}
                          >
                            + Option
                          </button>) : null}
                      </div>
                    </div>
                  </div>
                ) : null}

              </div>
            </div>

            <div className="col-12 row">
              <div
                className={`${this.state.mode === "gradingModeTeacher" ||
                  this.state.mode === "gradingModeStudent"
                  ? "col-9"
                  : "col-12"
                  } row align-items-center`}
              >

              </div>

              {this.state.mode === "gradingModeTeacher" ||
                this.state.mode === "gradingModeStudent" ? (
                <div className="col-8 ml-4 row align-items-start align-content-start p-2">
                  <label className="align-self-start">Feedback</label>
                  <textarea
                    className="form-control align-self-stretch "
                    name=""
                    id=""
                    value={this.state.feedback}
                    rows={5}
                    disabled={
                      this.state.mode === "gradingModeStudent" ? true : false
                    }
                    onChange={(e) =>
                      this.setState({ feedback: e.target.value })
                    }
                  ></textarea>
                </div>
              ) : null}
            </div>

            <hr className="col-10" style={{ color: "white", backgroundColor: "white" }} />

            {this.state.mode === "editingMode" ? (
              <React.Fragment>

                <div className="col-12 row my-3 align-items-start">
                  <input
                    type="checkbox"
                    name=""
                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="ShuffleOptions"
                    checked={this.state.shuffleOptions}
                    onChange={(e) =>
                      this.setState({ shuffleOptions: e.target.checked })
                    }
                  />
                  <label htmlFor="ShuffleOptions" style={{ cursor: "pointer" }}>
                    Shuffle Options
                  </label>
                </div>
                <div className="col-12 row my-3 align-items-start">
                  <input
                    type="radio"
                    name="match"
                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="exactMatch"
                    value="exactMatch"
                    checked={this.state.scoreMode == "exactMatch" ? true : false}
                    onChange={(e) =>
                      this.setState({ scoreMode: e.target.value })
                    }
                  />
                  <label htmlFor="exactMatch" style={{ cursor: "pointer" }} className="mr-5">
                    Exact match
                  </label>
                  <input
                    type="radio"
                    name="match"
                    value="partialMatch"
                    checked={this.state.scoreMode == "partialMatch" ? true : false}

                    className="mr-2"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    id="partialMatch"
                    onChange={(e) =>
                      this.setState({ scoreMode: e.target.value })
                    }
                  />
                  <label htmlFor="partialMatch" style={{ cursor: "pointer" }}>
                    Partial match
                  </label>
                </div>
              </React.Fragment>
            ) : null}


            {this.state.mode === "editingMode" || this.state.mode === "gradingModeTeacher" ? (
              <div className="col-12 row m-0 p-0 align-items-center align-items-center ">
                <div className="col-sm-6">

                  <label className=" text-white ml-1">Difficult level: </label>
                  <select
                    className="form-control "
                    name="difficult"
                    id="difficult"
                    defaultValue={this.state.difficult}
                    onChange={(e: any) =>
                      this.setState({
                        difficult: e.target.value,
                      })
                    }
                    disabled={
                      this.state.mode === "gradingModeTeacher" ? true : false
                    }
                  >
                    <option value="low">low</option>
                    <option value="medium">medium</option>
                    <option value="high">high</option>
                  </select>
                </div>

                <div className="col-sm-6">

                  <label className="text-white">Cognitive level: </label>
                  <select
                    className="form-control "
                    name="cognitive"
                    id="cognitive"
                    defaultValue={this.state.cognitive}
                    onChange={(e: any) =>
                      this.setState({
                        cognitive: e.target.value,
                      })
                    }
                    disabled={
                      this.state.mode === "gradingModeTeacher" ? true : false
                    }
                  >
                    <option value="understanding">understanding</option>
                    <option value="applying">applying</option>
                    <option value="problem solving/critical thinking">problem solving / critical thinking</option>
                  </select>
                </div>
              </div>

            ) : null}

            {this.state.mode === "editingMode" ? (
              <div>


                <div className="col-12 my-3  d-flex justify-content-between">

                </div>
                <button
                  className="btn btn-success"
                  onClick={() =>
                    this.setState({
                      showObjectives: !this.state.showObjectives,
                    })
                  }
                >
                  Learning objective
                </button>
              </div>
            ) : null}

            {this.state.showObjectives && (
              <div className="col-12 row my-3">
                <label>Learning objective</label>
                <textarea
                  className="col-12 form-control"
                  rows={10}
                  value={this.state.objectives}
                  onChange={(e) =>
                    this.setState({ objectives: e.target.value })
                  }
                ></textarea>
              </div>
            )}

            {this.state.mode !== "examMode" && <div className="text-center col-12">
              <button
                className="btn btn-warning btn-lg rounded col-3"
                onClick={() => this.onSaveQuestion()}

              >
                {" "}
                Save
              </button>
            </div>}

          </div>
        </div>
        <div className="my-5"></div>
      </div>
    );
    // }
  }
}
function mapStateToProps(state: ApplicationState) {
  const Store = { store: state };
  return Store;
}

export default withRouter<any, any>(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
  )(LabelQ as any)
);
