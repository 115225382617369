import $ from "jquery";
export function handleCount(h: any) {
  var content = h;


  var tempallword = $('<div>').html(content).text();
  let regexp: RegExp = /[ ]*[\.]*[<A-z>'"|\d]+[\.]*[ ]*/g;
  const str = tempallword;
  const array = [...str.matchAll(regexp)];
  let count = h.length;
  if (count < 1 && $('<div>').html(content).find('img').length < 1) {
    return true;
  }
  else {
    return false;
  }
}
export function shuffle(array: any) {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
export function createRandomNum(num: any) {
  return Math.floor(Math.random() * (num));
}
export function createRandomIndex(currentIndex: any) {
  let limit = 50;
  const endRange = currentIndex;
  let res = createRandomNum(endRange);
  while ((res >= currentIndex) && limit) {
    limit--;
    res = createRandomNum(endRange);
  }
  return res;
}
export function shuffleOps(array: any) {
  const op1: any = [];
  const op2: any = [];
  const oldOp2: any = array.map((op: any) => op.op2).reverse()
  array.forEach((op: any, currentIndex: any) => {
    let limit = 50;
    let randomIndex = createRandomIndex(oldOp2.length);
    const op_op1 = op.op1;
    op1.push(op_op1);
    let oldOp2_randomIndex = oldOp2[randomIndex];
    while ((oldOp2_randomIndex?.key === op_op1?.key) && limit) {
      limit--;
      randomIndex = createRandomIndex(oldOp2.length);
    }
    oldOp2_randomIndex = oldOp2[randomIndex];
    if ((!oldOp2_randomIndex) || (oldOp2_randomIndex?.key === op_op1?.key)) {
      const index = oldOp2?.findIndex((nested_op: any) => nested_op?.key != op_op1?.key);
      if (index !== -1) {
        randomIndex = index;
        oldOp2_randomIndex = oldOp2[randomIndex];
      }
    }
    op2.push(oldOp2_randomIndex);
    oldOp2.splice(randomIndex, 1);
  });
  const newOps: any = [];
  array.forEach((op: any, currentIndex: any) => {
    newOps.push({
      op1: op1[currentIndex],
      op2: op2[currentIndex],
    });
  });
  return newOps;
}