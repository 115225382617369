import React from "react";
import { Component } from "react";
import { NavLink, Prompt, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import "../../css/DashBoard.css";
import "../../css/assessment.css";
import { actionCreators as AssessmentActionCreator } from "../../store/Assessment";
import MCQ from "../questions/MCQComponent";
import TrueOrFalse from "../questions/TrueOrFalse";
import OrderComponent from "../questions/OrderComponent";
import MatchQComponent from "../questions/MatchQComponent";
import EssayComponent from "../questions/EssayComponent";
import AudioComponent from "../questions/AudioComponent";
import LabelQComponent from "../questions/LabelQComponent";
import ImageHighlightComponent from "../questions/ImageHighlightComponent";
import FileUploadEnhanced from "../questions/FileUploadEnhanced";
import FillTheBlank from "../questions/FillTheBlank";
import VideoComponent from "../questions/VideoComponent";
import "react-responsive-modal/styles.css";
import { makeId } from "../../helpers/func";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import MultiStepFunc from "../../hooks/MultiStepFunc";
import Cookies from "js-cookie";

import ProcessCardSingleState from "../questions/ProcessCardSingleState";
import { MathJax } from "better-react-mathjax";

const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
    onSaveQuestionHandler: (data: any) =>
        dispatch(AssessmentActionCreator.saveQuestion(data)),
    AddAssessment: (data: any) =>
        dispatch(AssessmentActionCreator.AddAssessment(data)),
    StudentSubmiteExam: (data: any) =>
        dispatch(AssessmentActionCreator.StudentSubmiteExam(data)),
    StudentSubmiteExamPerQuestion: (data: any) =>
        dispatch(AssessmentActionCreator.StudentSubmiteExamPerQuestion(data)),
    StudentSilentSubmiteExam: (data: any) =>
        dispatch(AssessmentActionCreator.StudentSilentSubmiteExam(data)),
    StudentStartExam: (data: any) =>
        dispatch(AssessmentActionCreator.StudentStartExam(data)),
    UpdateGradingAssessment: (data: any) =>
        dispatch(AssessmentActionCreator.UpdateGradingAssessment(data)),

    GetVeiwExamAsStudent: (id: any) =>
        dispatch(AssessmentActionCreator.GetVeiwExamAsStudent(id)),

    resetGotData: () =>
        dispatch(AssessmentActionCreator.resetGotData()),

});

class VeiwExamAsStudent extends Component<any, any> {
    buttonRef: any;
    examContainerRef: any;
    constructor(props: any) {
        super(props);
        this.buttonRef = React.createRef();
        this.examContainerRef = React.createRef();
    }

    modalContent: any = null;
    examMode = "examMode";
    handleFullScreenClick() {
        const elem: any = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    }

    componentDidMount() {
        $("#preloader").hide();
        this.props.resetGotData()
        let id = this.props.location.search.split("=")[1];
        this.props.GetVeiwExamAsStudent(id)
        // window.beforeunload = function () { localStorage.setItem("fexam", id); handleUnload() };
        // window.addEventListener('beforeunload', this.handleBeforeUnload);
        const assessment = this.props.assessment;
        this.examContainerRef.current.classList.remove('hide-element')
        if (assessment?.istimed === "1") {
            this.SetTimer(parseInt(assessment.duration));
        }
        document.addEventListener("fullscreenchange", (e) => {
            e.preventDefault()
            if (document.fullscreenElement) {

            } else {
                Swal.fire({
                    title: 'Warning?',
                    html: `You are leaving full screen mode this will cause the exam to be submitted<br>click back to full screen to avoid that`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Get Back To Full Screen!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.handleFullScreenClick()
                    } else {
                        this.submitExam();
                    }
                })
            }
        })
    }


    componentWillUnmount() {
        // window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentDidUpdate() {
        const assessment = this.props.assessment;
        let id = this.props.location.search.split("=")[1];
    }

    onSaveQuestion = (q: any) => {
        this.props.onSaveQuestionHandler(q);
    };

    submitExam = (val: any = "nothing") => {

        Swal.showLoading();


        const data = {
            id: this.props?.assessment.id,

            questions: this.props.questions,

        };
        this.props.StudentSubmiteExam(data);
        // localStorage.setItem("fexam", "");
        // localStorage.setItem("key", "");

    };

    submitExamButton = (event: any) => {
        // event.preventDefault();

        Swal.showLoading();

        const data = {
            id: this.props.assessment.id,

            questions: this.props.questions,

        };
        this.props.StudentSubmiteExam(data);
        // localStorage.setItem("fexam", "");
        // localStorage.setItem("key", "");

    };

    silentSubmitExam = () => {
        Swal.showLoading();
        const data = {
            id: this.props.assessment.id,
            questions: this.props.questions,
        };
        this.props.StudentSilentSubmiteExam(data);
        // localStorage.setItem("fexam", "");
        // localStorage.setItem("key", "");

    };

    submitPerQuestionExam = () => {
        Swal.showLoading();
        const data = {
            id: this.props.assessment.id,
            questions: this.props.questions,
        };
        this.props.StudentSubmiteExamPerQuestion(data);
    };
    removeQuest = (e: any) => {
        this.props.DeleteQuestion(e.id)
    }

    getMinutes = () => {
        let m = $("#TMinutes").text();
        return parseInt(m) ?? 0;
    }
    getSeconds = () => {
        let m = $("#TSeconds").text();
        return parseInt(m) ?? 0;
    }
    countDown = () => {
        let minutes = this.getMinutes();
        let seconds = this.getSeconds();
        seconds = seconds - 1;
        if (seconds === -1) {
            minutes = minutes - 1;
            if (minutes === -1) {
                this.submitExam();
                $("#TMinutes").text(this.TwoDF(0));
                $("#TSeconds").text(this.TwoDF(0));
                return;
            }
            seconds = 59;
        }
        if (minutes === 0) {
            $("#TSeconds").css("color", "red");
        }
        $("#TMinutes").text(this.TwoDF(minutes));
        $("#TSeconds").text(this.TwoDF(seconds));

    }

    TwoDF = (n: number) => {
        if (n <= 9) {
            return `0${n}`;
        } else {
            return `${n}`;
        }
    }
    SetTimer = (t: number) => {
        $("#TMinutes").text(this.TwoDF(t));
        $("#TSeconds").text("01");
        this.countDown();

    }

    render() {
        if (this.props.loading) {
            Swal.close();
        }

        //examMode
        //gradingModeTeacher
        //gradingModeStudent
        const editQuestionsRenderer = (s: any, q: any, index: any) => {
            q.mode = this.examMode;
            switch (s) {
                case "MCQ":
                    return (
                        <MCQ
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "OrderComponent":
                    return (
                        <OrderComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "MatchQComponent":

                    return (
                        <MatchQComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "ProcessCard":

                    return (
                        <ProcessCardSingleState
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                            type={"ProcessCard"}
                        />
                    );
                    return null;
                case "ProductCard":

                    return (
                        <MatchQComponent
                            type={"ProductCard"}
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "LabelQComponent":
                    return (
                        <LabelQComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "TrueOrFalse":
                    return (
                        <TrueOrFalse
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "EssayComponent":
                    return (
                        <EssayComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "VideoComponent":
                    q.showRecordingOpt = true
                    return (
                        <VideoComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "AudioComponent":
                    return (
                        <AudioComponent
                            quest={q}
                            index={index}

                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "FileUploadEnhanced":
                    return (
                        <FileUploadEnhanced
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "FillTheBlank":
                    return (
                        <FillTheBlank
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                case "ImageHighlight":

                    return (
                        <ImageHighlightComponent
                            quest={q}
                            index={index}
                            onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                        />
                    );
                    return null;
                default:
                    return (
                        null
                    );
            }
        };

        // const examsArr : any = []
        const examsArr = this.props?.questions?.map((q: any, index: number) => {
            return (
                <React.Fragment key={index}>
                    <MathJax>
                    {editQuestionsRenderer(q.type, q, index + 1)}
                    </MathJax>
                </React.Fragment>
            );
        })

        const onConfirm = () => {
            window.close();
        }


        let MSG = null;
        if (this.props.submitAssessmentMsg != "") {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: this.props.submitAssessmentMsg,
            }).then(() => {
                onConfirm()
            })
        }





        return (

            this.props.studentAssessmentLoading != true ? (
                <MathJax>
                <div>

                    {MSG}

                    <div className=" d-flex d-md-block flex-nowrap wrapper ">

                        <div ref={this.examContainerRef} className="hide-element col-md-9 float-center mx-auto pl-md-2 pt-2 main">
                            <div>
                                <div className="col-12 mx-auto align-items-center rounded  text-white">
                                    <NavLink to={"/dashboard/assessments"} className="btn btn-secondary mb-3">
                                        <i className="fa fa-arrow-left" aria-hidden="true" />
                                    </NavLink>
                                    <h3 className="text-shadow mt-2 ml-3 text-ivyblue">{this.props.assessment?.name} : </h3>
                                    {/* {this.props.questions?.map((q: any, index: number) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {editQuestionsRenderer(q.type, q, index + 1)}
                                            </React.Fragment>
                                        );
                                    })} */}
                                    <MultiStepFunc
                                        examsArr={examsArr}
                                        buttonRef={this.buttonRef}
                                        submitPerQuestion={this.submitPerQuestionExam}
                                    >
                                    </MultiStepFunc>
                                    {this.props.assessment?.status === "Pending" ?

                                        <div className="text-center my-5 ">
                                            <button
                                                className="btn btn-warning text-white w-100"
                                                onClick={this.submitExam}
                                                // onClick={this.submitExamButton}
                                                ref={this.buttonRef}
                                            // disabled={true}
                                            >
                                                Submit exam
                                            </button>
                                        </div>
                                        : null

                                    }


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                </MathJax>

            ) : (
                <MathJax>
                <div className="d-flex justify-content-center align-items-center mt-5">
                    <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
                </div></MathJax>
            )

        );
        // }
    }
}
function mapStateToProps(state: any) {
    return {
        questions: state.Assessment.questions,
        assessment: state.Assessment.assessment,
        loading: state.Assessment.loading,
        assessmentMsg: state.Assessment.assessmentMsg,
        addAssessmentloading: state.Assessment.addAssessmentloading,
        grades: state.Assessment.grades,
        subjects: state.Assessment.subjects,
        submitAssessmentMsg: state.Assessment.submitAssessmentMsg,
    };
}

export default withRouter(
    connect(
        mapStateToProps, // Selects which state properties are merged into the component's props
        mapDispatchToProps // Selects which action creators are merged into the component's props
    )(VeiwExamAsStudent as any)
);
