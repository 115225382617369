import React from "react";
import "react-step-progress-bar/styles.css";
import "../css/MultiStepProgressBar.css";

const { ProgressBar, Step } = require("react-step-progress-bar")
const MultiStepProgressBar = (props: any) => {
    return (
        <ProgressBar
            // percent={((props.currentStepIndex) * 100) / props.steps.length}

            percent={((props.currentStepIndex + 1) * 100) / props.steps.length}
            // percent={30}
            filledBackground="green"
        >
            {props.steps.length ?
                <>
                    {Array.from({ length: props.steps.length }).map((element, indexmap) =>

                        <Step transition="scale" key={indexmap}>
                            {({ accomplished, index }: any) => (
                                <div
                                    className={`indexedStep ${indexmap == props.currentStepIndex ? "accomplished" : null}`}
                                    onClick={() => { props.goTo(indexmap) }}
                                    style={{ cursor: 'pointer', fontSize: '20px' }}
                                >

                                    {indexmap + 1}
                                </div>
                            )}
                        </Step>
                    )}


                </>
                : null}
            {/* <Step transition="scale" >
                {({ accomplished, index }: any) => (
                    <div
                        className={`step ${accomplished ? "completed" : null}`}
                    >
                        1
                    </div>
                )}
            </Step>
            <Step transition="scale">
                {({ accomplished, index }: any) => (
                    <div
                        className={`step ${accomplished ? "completed" : null}`}
                    >
                        2
                    </div>
                )}
            </Step>
            <Step transition="scale">
                {({ accomplished, index }: any) => (
                    <div
                        className={`step ${accomplished ? "completed" : null}`}
                    >
                        3
                    </div>
                )}
            </Step> */}
        </ProgressBar>
    )
};

export default MultiStepProgressBar