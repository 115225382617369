import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";


const useCheckSuperAdmin = () => {
    const token = Cookies.get("quiztoken");
    
    //const userData = useSelector((state) => state.user)
    const [ifSuperAdmin, setIfSuperAdmin] = useState(false)
    useEffect(() => {
        if(token === undefined){
            setIfSuperAdmin(false)
            return;
        }
        if (token !== ""){
            try {
                const decodedToken = jwt_decode(token);
                if (decodedToken !== null) {
                    let userData = decodedToken.data
                    let emailDomain = userData.userName?.split("@")[1]
                    // if (emailDomain === "iatseg.org" || emailDomain === "ivyis.org") {
                    if (emailDomain === "iatseg.org")  {
                        setIfSuperAdmin(true)
                    } else {
                        setIfSuperAdmin(false)
                    }
                }
            } catch (error) {
                
            }
        }
    }, [token])
    return ifSuperAdmin
}

export default useCheckSuperAdmin