import { Reducer } from "redux";
import { AppThunkAction } from ".";
import { requestWithAxios } from "../helpers/func";
import {
  ADD_ASSESSMENT,
  SAVE_QUSTION,
  GET_EXAMS,
  GET_EXAM,
  LOADING,
  RESET_GOTDATA,
  RESET_ASSESSMENT,
  GET_GRADES,
  GET_SUBJECTS,
  ADD_SUBJECT,
  VIEW_EXAM,
  STUDENT_SUBMIT_EXAM,
  STUDENT_SUBMIT_EXAM_PER_QUESTION,
  STUDENT_SUBMIT_EXAM_SILENT,
  DELETE_QUESTION,
  GET_COURSES,
  GET_PERIODS,
  GET_CATEGORIES,
  GET_TEACHERS,
  GET_STUDENTS_EXAMS,
  GRADING_EXAM,
  GET_STUDENT_EXAM,
  STUDENT_ASSESSMENT_LOADING,
  PUBLISH_ASSESSMENT,
  STUDENT_START_EXAM,
  GET_COURSE_INFO,
  COPY_ASSESSMENT,
  DELETE_ASSESSMENT,
  PUBLISHED_SUCCESSFULLY,
  SETEXAMTYPE,
  Generate_Question,
} from "./ActionTypes";
import * as Initial from "./initialStatus";

import { BASE_URL } from "../variables/api";
import Swal from "sweetalert2";

/**
 * status
 *  204 => ok same data in server
 *  401 => Error unauthorized
 *  403 => Error Forbidden not loggedin
 *  500 => Error server error
 *  -1 => Error
 */

interface AddAssessment {
  type: string;
  msg: string;
  Questions: [] | undefined;
}

type KnownAction = AddAssessment;

export const actionCreators = {
  GetVeiwExamAsStudent:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        dispatch({ type: STUDENT_ASSESSMENT_LOADING, value: true });
        const res = await requestWithAxios(
          BASE_URL + "admin/getexamasstudent.php",
          { id: id }
        );

        dispatch({ type: GET_STUDENT_EXAM, res: res.data.data });
        dispatch({ type: STUDENT_ASSESSMENT_LOADING, value: false });

      },
  AddAssessment:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: ADD_ASSESSMENT, value: true, MSG: "" });

        const res = await requestWithAxios(BASE_URL + "admin/addexam.php", data)



        dispatch({ type: ADD_ASSESSMENT, value: false, res_status: res.data.data.status, MSG: res.data.data.msg });


      },
  GenerateQuestion:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: Generate_Question, value: data, MSG: "" });




      },
  StudentSubmiteExam:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const loading = () => {
          Swal.fire({
            html: '<div style="color:blue;"> Loading....... </div>',
            showConfirmButton: false,
          });
        };
        dispatch({ type: STUDENT_SUBMIT_EXAM, value: true, MSG: "" });
        loading()
        const res = await requestWithAxios(
          BASE_URL + "student/submitexam.php",
          data
        ).catch(error => {
          // Check if the error is due to a network issue
          if (!navigator.onLine || error.message === "Network Error") {
            // Wait for 5 seconds and then retry
            setTimeout(() => actionCreators.StudentSubmiteExam(data), 2000);
          } else {
          }
        });
        if (res?.data) {
          //document.exitFullscreen()
          dispatch({ type: STUDENT_SUBMIT_EXAM, value: false, MSG: res.data.msg });
        } else {

          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error!",
            html: "Please check your internet and try again please don't close the window otherwise your answers will be lost",
            allowOutsideClick: true,
            showConfirmButton: true,
            showCancelButton: false
          }).then(() => {
          })

        }

      },
  StudentSilentSubmiteExam:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: STUDENT_SUBMIT_EXAM_SILENT, value: true, MSG: "" });

        const res = await requestWithAxios(
          BASE_URL + "student/submitexam.php",
          data
        ).catch(error => {
          // Check if the error is due to a network issue
          if (!navigator.onLine || error.message === "Network Error") {
            // Wait for 5 seconds and then retry
            setTimeout(() => actionCreators.StudentSilentSubmiteExam(data), 2000);
          } else {
          }
        });
        dispatch({ type: STUDENT_SUBMIT_EXAM_SILENT, value: false, MSG: res.data.msg });
      },
  StudentSubmiteExamPerQuestion:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: STUDENT_SUBMIT_EXAM_PER_QUESTION, value: true, MSG: "" });

        const res = await requestWithAxios(
          BASE_URL + "student/studentsubmitquestion.php",
          data
        );
        dispatch({ type: STUDENT_SUBMIT_EXAM_PER_QUESTION, value: false, MSG: res.data.msg });
      },
  StudentStartExam:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: STUDENT_START_EXAM, value: true, MSG: "" });

        const res = await requestWithAxios(
          BASE_URL + "student/startexam.php",
          data
        );
        dispatch({ type: STUDENT_START_EXAM, value: false, MSG: res.data.msg });
      },
  AddSubject:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: ADD_SUBJECT, value: true });
        const res = await requestWithAxios(
          BASE_URL + "admin/addsubject.php",
          data
        );
        dispatch({ type: ADD_SUBJECT, value: false, MSG: res.data.msg });
      },
  UpdateAssessment:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: ADD_ASSESSMENT, value: true });


        const res = await requestWithAxios(
          BASE_URL + "admin/updateexam.php",
          data
        );

        dispatch({ type: ADD_ASSESSMENT, value: false, MSG: res?.data?.msg });




      },
  SetExamType: (exam_type: any): AppThunkAction<any | any> =>
    async (dispatch, getState) => {





      dispatch({ type: SETEXAMTYPE, value: exam_type });




    },
  GetAssessments:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const res = await requestWithAxios(BASE_URL + "admin/getexams.php", data);

        dispatch({ type: GET_EXAMS, res: res.data.data });
      },
  GetStudentsExams:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const res = await requestWithAxios(
          BASE_URL + "admin/getstudentsexams.php",
          data
        );

        dispatch({
          type: GET_STUDENTS_EXAMS,
          res: res.data.data?.exams,
          count: res.data.data?.count,
        });
      },
  GetGrades: (): AppThunkAction<any | any> => async (dispatch, getState) => {
    const res = await requestWithAxios(BASE_URL + "admin/getgrades.php", "");
    dispatch({ type: GET_GRADES, res: res.data.data });
  },
  GetCourses: (): AppThunkAction<any | any> => async (dispatch, getState) => {
    const res = await requestWithAxios(BASE_URL + "admin/getcourses.php", "");
    dispatch({ type: GET_COURSES, res: res.data.data });
  },
  GetPeriods:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: LOADING, value: true });
        const res = await requestWithAxios(BASE_URL + "admin/getPeriods.php", {
          id: id,
        });
        dispatch({ type: GET_PERIODS, res: res.data.data });
        dispatch({ type: LOADING, value: false });
      },
  GetCategories:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {


        const res = await requestWithAxios(BASE_URL + "admin/getcategories.php", {
          id: id,
        });
        dispatch({ type: GET_CATEGORIES, res: res.data.data });
      },
  GetCategoriesTeachersPeriods:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {


        const res = await requestWithAxios(BASE_URL + "admin/get_teachers_periods_categories.php", {
          id: id,
        });
        const res_data_data: any = res?.data?.data;

        if (res_data_data.hasOwnProperty("categories")) {
          dispatch({ type: GET_CATEGORIES, res: res_data_data.categories });
        }

        if (res_data_data.hasOwnProperty("periods")) {
          dispatch({ type: GET_PERIODS, res: res_data_data.periods });
        }

        if (res_data_data.hasOwnProperty("teachers")) {
          dispatch({ type: GET_TEACHERS, res: res_data_data.teachers });
        }

      },

  GetSubjects: (): AppThunkAction<any | any> => async (dispatch, getState) => {
    const res = await requestWithAxios(BASE_URL + "admin/getsubjects.php", "");

    dispatch({ type: GET_SUBJECTS, res: res.data.data });
  },
  GetSubjectsGrade:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const res = await requestWithAxios(
          BASE_URL + "admin/getgradesubject.php",
          data
        );

        dispatch({ type: GET_SUBJECTS, res: res.data.data?.subjects });
        dispatch({ type: GET_COURSES, res: res.data.data?.courses });
      },
  GetTeachers:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const res = await requestWithAxios(BASE_URL + "admin/getteachers.php", {
          id: id,
        });
        dispatch({ type: GET_TEACHERS, res: res.data.data });
      },
  DeleteQuestion:
    (id: any): AppThunkAction<any | any> =>
      (dispatch, getState) => {
        dispatch({ type: "DELETE_QUESTION", id: id });
      },
  GetAssessment:
    (id: any, copy_assessment = false): AppThunkAction<any | any> =>
      async (dispatch, getState) => {


        dispatch({ type: LOADING, value: true });
        const res = await requestWithAxios(BASE_URL + "admin/getexam.php", {
          id: id,
        });

        dispatch({ type: GET_EXAM, res: res.data.data, copy_assessment });
        dispatch({ type: LOADING, value: false });
      },
  CopyAssessment:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {


      

        dispatch({ type: COPY_ASSESSMENT});
        // dispatch({ type: GET_EXAM, res: res.data.data });
        dispatch({ type: LOADING, value: false });
      },
  DeleteAssessment:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const res = await requestWithAxios(BASE_URL + "admin/deleteexam.php", {
          id: id,
        });
        dispatch({ type: DELETE_ASSESSMENT, });
      },
  GetPeriod:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: LOADING, value: true });
        const res = await requestWithAxios(BASE_URL + "admin/getexam.php", {
          id: id,
        });

        dispatch({ type: GET_EXAM, res: res.data.data });
        dispatch({ type: LOADING, value: false });
      },
  GetCategery:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: LOADING, value: true });
        const res = await requestWithAxios(BASE_URL + "admin/getexam.php", {
          id: id,
        });

        dispatch({ type: GET_EXAM, res: res.data.data });
        dispatch({ type: LOADING, value: false });
      },
  ViewAssessment:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: LOADING, value: true });
        const res = await requestWithAxios(BASE_URL + "admin/getexam.php", {
          id: id,
        });

        dispatch({ type: VIEW_EXAM, res: res.data.data });
        dispatch({ type: LOADING, value: false });
      },
  GradingAssessment:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: LOADING, value: true });
        const res = await requestWithAxios(BASE_URL + "admin/getstudentsol.php", {
          id: id,
        });

        dispatch({ type: GRADING_EXAM, res: res.data.data });
        dispatch({ type: LOADING, value: false });
      },
  GetGradedExam:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: LOADING, value: true });
        const res = await requestWithAxios(BASE_URL + "admin/getgradedexam.php", {
          id: id,
        });

        dispatch({ type: GRADING_EXAM, res: res.data.data });
        dispatch({ type: LOADING, value: false });
      },
  DisablePublishedSuccessfully:
    (): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: PUBLISHED_SUCCESSFULLY, value: false });
      },
  PublishAssessment:
    (id: any, students: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: LOADING, value: true });
        const res = await requestWithAxios(
          BASE_URL + "admin/publishassessment.php",
          { id: id, students: students }
        );

        dispatch({ type: GET_EXAMS, res: res.data.data });
        dispatch({ type: LOADING, value: false });
        dispatch({ type: PUBLISHED_SUCCESSFULLY, value: true });
      },
  PullAssessment:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: LOADING, value: true });
        const res = await requestWithAxios(
          BASE_URL + "admin/pullassessment.php",
          { id: id }
        );

        dispatch({ type: GET_EXAMS, res: res.data.data });
        dispatch({ type: LOADING, value: false });
      },
  GetStudentAssessment:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: STUDENT_ASSESSMENT_LOADING, value: true });
        const res = await requestWithAxios(
          BASE_URL + "admin/getstudentexam.php",
          { id: id }
        );


        if (res?.data) {


          dispatch({ type: GET_STUDENT_EXAM, res: res.data.data });
          dispatch({ type: STUDENT_ASSESSMENT_LOADING, value: false });
        } else {

          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error!",
            html: res.response.data.msg,
            allowOutsideClick: true,
            showConfirmButton: true,
            showCancelButton: false
          }).then(() => {
          })

        }

      },

  UpdateGradingAssessment:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: ADD_ASSESSMENT, value: true });
        const res = await requestWithAxios(
          BASE_URL + "admin/gradeexam.php",
          data
        );
        dispatch({ type: ADD_ASSESSMENT, value: false, MSG: res.data?.msg });
      },
  saveQuestion:
    (question: any): AppThunkAction<any | any> =>
      (dispatch, getState) => {

        dispatch({ type: SAVE_QUSTION, question: question });
      },
  resetGotData: (): AppThunkAction<any | any> => (dispatch, getState) => {
    dispatch({ type: RESET_GOTDATA });
  },
  resetAssessment: (): AppThunkAction<any | any> => (dispatch, getState) => {
    dispatch({ type: RESET_ASSESSMENT });
  },
  GetCourseInfo:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: GET_COURSE_INFO, value: true });
        const res = await requestWithAxios(
          BASE_URL + "admin/getcourseinfo.php",
          data
        );

        dispatch({ type: GET_COURSE_INFO, value: false, MSG: res.data.data });
      },
};

export const reducer: Reducer<any> = (
  state: any | undefined,
  incomingAction: any
): any => {
  if (state === undefined) {
    return Initial.Assessment;
  }

  const action = incomingAction;
  switch (action.type) {
    case SAVE_QUSTION:
     
      let tempQuestins = [...state.questions]
      let q = tempQuestins.find((q: any) => q.id == action.question.id);
      if (q) {
        let questions = [...tempQuestins];
        let index = questions.indexOf(q);
        questions[index] = action.question;

        return {
          ...state,
          questions: questions,
        };
      } else {
        return {
          ...state,
          questions: tempQuestins.concat(action.question),
        };
      }

    case DELETE_QUESTION:
      return {
        ...state,
        questions: state.questions.filter((q: any) => q.id != action.id),
      };
    case GET_EXAMS:
      return {
        ...state,
        assessments: action.res,
      };
    case GET_STUDENTS_EXAMS:
      return {
        ...state,
        studentsExams: action.res,
        studentExamCount: action.count,
      };
    case GET_GRADES:
      return {
        ...state,
        grades: action.res,
      };
    case SETEXAMTYPE:
      return {
        ...state,
        etype: action.value,
      };
    case GET_SUBJECTS:
      return {
        ...state,
        subjects: action.res,
      };
    case GET_TEACHERS:

      return {
        ...state,

        teachers: action.res,
        teacherids: action?.res?.map((t: any) => t.officialemail)?.join(","),
      };
    case GET_COURSES:
      return {
        ...state,
        courses: action.res,
      };
    case GET_PERIODS:
      return {
        ...state,
        periods: action.res,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.res,
      };
    case GET_EXAM:

      return {
        ...state,
        copy_assessment: action.copy_assessment ?? false,
        assessment: action.res,
        questions: action.res?.questions ?? [],
        editdata: true,
        gotdata: true,
      };
    case COPY_ASSESSMENT:
      return {
        ...state,
        copy_assessment: true,
       
        questions: action.res?.questions ?? [],
        editdata: true,
        gotdata: true,
      };
    case DELETE_ASSESSMENT:
      return {
        ...state,
        copy_assessment: false,
      };
    case VIEW_EXAM:
      return {
        ...state,
        assessment: action.res,
        questions: action.res.questions,
        viewdata: true,
      };
    case GRADING_EXAM:
      return {
        ...state,
        assessment: action.res,
        questions: action.res.answeredquestions,
        viewdata: true,
      };
    case GET_STUDENT_EXAM:
      return {
        ...state,
        assessment: action.res,
        questions: action.res.questions,
      };
    case PUBLISHED_SUCCESSFULLY:
      return {
        ...state,
        published_successfully: action.value,
        gotdata: false,
      };
    case LOADING:
      return {
        ...state,
        loading: action.value,
        gotdata: false,
      };
    case STUDENT_ASSESSMENT_LOADING:
      return {
        ...state,
        studentAssessmentLoading: action.value,
      };
    case RESET_GOTDATA:
      return {
        ...state,
        gotdata: false,
        viewdata: false,
        questions: [],
        addAssessmentloading: false,
        submitAssessmentMsg: "",
        assessmentMsg: "",

        // editdata: false,
        // assessment: null,

      };
    case RESET_ASSESSMENT:


      return {
        ...state,
        copy_assessment: false,
        assessment: null,
        addAssessmentloading: false,
        addSubjectloading: false,
        assessmentMsg: "",
        subjectMsg: "",
        questions: [],
      };
    case ADD_ASSESSMENT:
      return {
        ...state,
        addAssessmentloading: action.value,
        assessmentMsg: action.MSG,
        res_status: action.res_status,
      };
    case STUDENT_SUBMIT_EXAM:
      return {
        ...state,
        submitAssessmentloading: action.value,
        submitAssessmentMsg: action.MSG,
      };
    case STUDENT_SUBMIT_EXAM_PER_QUESTION:
      return {
        ...state,
        // submitAssessmentloading: action.value,
        // submitAssessmentMsg: action.MSG,
      };
    case STUDENT_SUBMIT_EXAM_SILENT:
      return {
        ...state,
        submitAssessmentloading: action.value,
        submitAssessmentMsg: action.MSG,
      };
    case STUDENT_START_EXAM:
      return {
        ...state,
        isInite: false,
        startAssessmentloading: action.value,
        startAssessmentMsg: action.MSG,
        assessment: { ...state.assessment, status: "Started" }
      };
    case ADD_SUBJECT:
      return {
        ...state,
        subjectMsg: action.MSG,
      };
    case GET_COURSE_INFO:
      return {
        ...state,
        editdata: false,
        questions: [],
        assessments: [],
        assesment: "",
        studentsExams: [],
        studentExamCount: 0,
        grades: [],
        subjects: [],
        groups: [],
        teachers: action.MSG?.course?.user?.split(", "),
        courseId: action.MSG?.course?.coursegroup_id,
        courseName: action.MSG?.course?.name,
        gradingCategoryId: action.MSG?.gradingCat?.id,
        gradingPeriodId: action.MSG?.gradingPeriod?.id,
        teacherids: action.MSG?.course?.usernames,
        courses: action.MSG?.courses,
        assessment: null,
        loading: false,
        subjectsloading: false,
        addAssessmentloading: false,
        submitAssessmentloading: false,
        startAssessmentloading: false,
        addSubjectloading: false,
        studentAssessmentLoading: false,
        assessmentMsg: "",
        submitAssessmentMsg: "",
        startAssessmentMsg: "",
        subjectMsg: "",
        gotdata: false,
        viewdata: false,
      };
  }

  return state;
};
