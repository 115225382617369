import * as React from 'react';
import Swal from "sweetalert2";
import axios from "axios";
import { Translate } from '../../helpers/Translate';
import { BASE_URL } from '../../variables/api';
import { Modal } from "react-responsive-modal";
const Transition = React.forwardRef(function Transition(
    props: any,
    ref: React.Ref<unknown>,
) {
    return <div></div>
});

export default function DocumentModal({ children, open, setOpen, file, section_id }: any) {

    const [fullWidth, setFullWidth] = React.useState(true);
    const [temproaryCompleted, setTemproaryCompleted] = React.useState<any>(false);
    const [maxWidth, setMaxWidth] = React.useState<any>('sm');
    // const theme = useTheme();
    const completedBtnRef = React.useRef<any>(null)
    const t = new Translate()
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleMarkCompleted = () => {
        completedBtnRef.current.disabled = true
        if ((!file) || (!section_id)) {
            Swal.fire(t.translate('error sending file info'))
            completedBtnRef.current.disabled = false
            return
        } else {
            Swal.showLoading();
            axios.post(`${BASE_URL}/lmsstats/markfilecompleted.php`,
                {
                    file_id: file.id,
                    section_id: section_id
                }
            )
                .then((res) => {
                    Swal.fire(t.translate('Marked As Completed'))
                    setTemproaryCompleted(true)
                }).catch((err) => {
                    Swal.fire(t.translate('error sending file info'))
                    completedBtnRef.current.disabled = false

                })
        }
    }



    return (

        <div>
            <Modal
                classNames={{
                    modal: "customModal",
                }}
                closeOnOverlayClick={false}
                open={open}
                onClose={handleClose}
                center
            >

                <div
                >
                    < div style={{ padding: '5px', height: "calc(100vh - 55px)", overflow: "scroll" }}>
                        {children}
                    </div >

                    {/* <Divider /> */}

                    <div style={{ backgroundColor: '#dddff3' }} className='justify-content-center'>
                      
                        < button
                            className="btn btn-danger"
                            onClick={() => { setOpen(false) }} style={{ backgroundColor: 'red !important', color: '#fff !important' }}  >
                            {t.translate("close")}
                        </ button>
                    </div >
                </div >

            </Modal>

        </div >


    );
}
