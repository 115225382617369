//import { resolve } from "dns";
//import { rejects } from "assert";
//import * as Fingerprint2 from 'fingerprintjs2';
import { Convert } from './typeConv';
//import { isNullOrUndefined } from 'util';
import * as Initial from '../store/initialStatus';

import { url as Durl, ConnString } from '../store/initialStatus';
import axios from 'axios';
import AWS from 'aws-sdk';
import Swal from 'sweetalert2';
const REACT_APP_AWS_REGION = "us-east-2";
const REACT_APP_AWS_ACCESS_KEY_ID = "AKIAQ4XA32XRLCWLSKFG";
const REACT_APP_AWS_SECRET_ACCESS_KEY = "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";
const S3_BUCKET = "ivyis"

AWS.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY
})
const s3 = new AWS.S3(
  {
    params: { Bucket: S3_BUCKET },
    region: REACT_APP_AWS_REGION
  }
);
function truncateString(str: any, maxLength: any) {
  return str.length > maxLength ? str.slice(0, maxLength - 1) + '...' : str;
}


function customUrlEncode(url: any) {
  let encodedUrl = '';

  for (const char of url) {
    if (/[A-Za-z0-9\-_\.~:\/]/.test(char)) {
      // Characters that are allowed to remain unchanged
      encodedUrl += char;
    } else {
      // Encode other characters
      encodedUrl += encodeURIComponent(char);
    }
  }

  return encodedUrl;
}

// Function to convert image URL to Base64
export async function imageUrlToBase64(imageUrl:any) {
  // Fetch the image
  const response = await fetch(imageUrl);
  // Ensure the response is valid
  if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
  }
  // Read the response as a Blob
  const blob = await response.blob();

  // Create a FileReader to convert the Blob to Base64
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
  });
}
export const  base64ToBlob =(base64:any, mimeType:any) => {

  const byteCharacters = atob(base64.replace(/^data:(image|audio|video)\/\w+;base64,/, ''));
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], {type: mimeType});
}
export const handleFileUpload2s3 = async (file: any, id: any) => {
  // setShowProgress(true)
  //  e.preventDefault();
  const formData = new FormData();
  Swal.showLoading();

 
  if (!file) {
    console.error('No file selected for upload');
    return;
  }
  // Replace with your bucket name
  const bucketName = 'ivyis';
  // Unique object key for the file
  const rootPath = "Assessments";

  const file_name = file.name;
  // Extract the file extension
  const fileExtension = file_name.split(".").pop();

  // Remove the original file extension
  const baseName = file_name.replace(`.${fileExtension}`, "");

  // Create a new Date object
  const currentDate = new Date();

  // Get the individual components of the timestamp
  const year = currentDate.getFullYear(); // e.g., 2024
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // e.g., 04
  const day = currentDate.getDate().toString().padStart(2, "0"); // e.g., 22
  const hours = currentDate.getHours().toString().padStart(2, "0"); // e.g., 06
  const minutes = currentDate.getMinutes().toString().padStart(2, "0"); // e.g., 30
  const seconds = currentDate.getSeconds().toString().padStart(2, "0"); // e.g., 45

  // Combine the components into a timestamp string
  const timestamp = `${year}${month}${day}${hours}${minutes}${seconds}`;



  // Append the timestamp and the file extension
  const modifiedString = `${truncateString(baseName, 50)}_${timestamp}.${fileExtension}`;


  const objectKey = `${rootPath}/${id}/${modifiedString}`;
  const encodedUrl = customUrlEncode(objectKey);
  const driveid = `https://${bucketName}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/${encodedUrl}`;

  const contentType = file.type;

  const param = {
    ACL: 'public-read',
    Bucket: bucketName,
    Key: objectKey,
   ContentType : contentType,
    Body: file
  };


  try {

    return new Promise((resolve, reject) => {
      // Simulating asynchronous upload process
      var upload = s3.putObject(param).on("httpUploadProgress", (evt: any) => {

        // setLoadingBar(`${((evt.loaded * 100) / evt.total)}` + "%");
      }).promise();
  
       upload.then((d: any) => {

        resolve(driveid);
    
        // alert("File uploaded successfully.");
        // setShowProgress(false)
  
      }).catch((err) => {
        reject(err);
      })
  });

   
    
  } catch (error) {
    if ((error as any).code === 'NotFound') {
      console.error('Bucket does not exist or is not accessible');
      return;
    }
    throw error;
  }





}


export const ivy_assessment_platform_datatable_tabs_localStorageItem_name = "ivy_assessment_platform_datatable_tabs_arr"

export const update_ivy_assessment_platform_datatable_tabs_arr = (ivy_assessment_platform_datatable_tabs_arr: any, ivy_assessment_platform_datatable_obj: any) => {
  if (Array.isArray(ivy_assessment_platform_datatable_tabs_arr)) {
    ivy_assessment_platform_datatable_tabs_arr = ivy_assessment_platform_datatable_tabs_arr.filter((x: any) => x.tab_name != ivy_assessment_platform_datatable_obj.tab_name)
    ivy_assessment_platform_datatable_tabs_arr = [...ivy_assessment_platform_datatable_tabs_arr, ivy_assessment_platform_datatable_obj]
  }

  else {
    ivy_assessment_platform_datatable_tabs_arr = [ivy_assessment_platform_datatable_obj]
  }

  localStorage.setItem(ivy_assessment_platform_datatable_tabs_localStorageItem_name, JSON.stringify(ivy_assessment_platform_datatable_tabs_arr));

}

export async function requestWithAxios(url: any, data: any) {

  var result = await axios
    .post(
      url,
      data
    )
    .then((res) => { return res })
    .catch((err) => { return err });
  return result;
}

export var Request = (url = '', method = 'POST', data = {}, token = "") => {
  if (url.search('http:') === -1 && url.search('https:') === -1) { url = Durl + url; }
  let FingerPrint = sessionStorage.getItem('fp');
  if (FingerPrint === null) {
    FingerPrint = RandomString(32);
    sessionStorage.setItem('fp', FingerPrint);

  }
  return new Promise<{ status: number, json: any }>(async (resolve, rejects) => {
    let body: string;
    if (url === Initial.url + "/api/account/login") {
      let MSG = {
        Body: JSON.stringify(data)
      };
      body = (JSON.stringify(MSG));
    } else {
      let MSG = {
        Body: JSON.stringify(data)
      }
      body = (JSON.stringify(MSG));

    }
    let MyBody = { data: body }
    try {
      await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',

          'time': (new Date()).toDateString(),
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //form: JSON.stringify({id:"DSdsdsdsd"}),

        body: JSON.stringify(MyBody)  // body data type must match "Content-Type" header
      }).then(async (response) => {
        var json: any = {};
        /*          response.text().then(function (text) {
                    
                });  */
        try {
          //  
          json = await response.json();
        } catch (err) {

        }
        //  
        /* let decryptedObj;
        try {
          //  
          decryptedObj =  json.data;  
          delete decryptedObj['__type'];
          for(var i in decryptedObj){
            delete decryptedObj[i]['__type'];
          } 
        } catch (err) {
          decryptedObj = {}
       } */

        resolve({ status: response.status, json: json });
      }).catch((err) => {

        rejects(err);
      });
    } catch (error) {

      rejects(error)
    }
  })
  // Default options are marked with *

  //return response.json(); // parses JSON response into native JavaScript objects
}

export var ShortRequest = (url = '', method = 'post', data = {}) => {
  //  
  let conString = ConnString();
  let res = Request(url, method, data, conString.token);
  return res;
}

export function setCookie(name: string, val: string) {
  const date = new Date();
  const value = val;

  // Set it expire in 3 days
  date.setTime(date.getTime() + (3 * 24 * 60 * 60 * 1000));

  // Set it
  document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
}
export function getCookie(name: string) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");

  if (parts ? true : false && parts.length === 2) {
    let x = parts.pop();

    return x ? x.split(";").shift() : "";
  }
  return "";
}

export function deleteCookie(name: string) {
  const date = new Date();

  // Set it expire in -1 days
  date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

  // Set it
  document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
}

export function asciiToUint8Array(s: string) {
  return new TextEncoder().encode(s);
}

export function RandomString(length: number) {
  var result = window.crypto.getRandomValues(new Uint8Array(length))
  return Convert.ab2base64str(result);
}

export function makeId(length: number) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export function objectsAreSame(x: any, y: any) {
  var AreSame = true;
  if (y == null || y === undefined) {
    return false;
  }
  for (var propertyName in x) {
    if (typeof x[propertyName] === 'object') {
      if (objectsAreSame(x[propertyName], y[propertyName]) === false) {
        AreSame = false;
        break;
      }
    } else {
      if (x[propertyName] !== y[propertyName]) {
        AreSame = false;
        break;
      }
    }
  }
  return AreSame;
}

export function Masker(Arr: Array<any>, Mask: number | null | undefined) {
  let res: Array<any> = [];
  if (Mask === null || Mask === undefined) {
    for (let i = 0; i < Arr.length; i++) {
      if (!Arr[i].IsHidden) { res.push(Arr[i]); }

    }
    return res;
  }
  var bitArr = Convert.int2Binary(Mask);
  for (let index = 0; index < bitArr.length; index++) {
    if (bitArr[index] && !Arr[index].IsHidden) { res.push(Arr[index]); }

  }
  return res;
}
export function GenMask(Arr: Array<any>, subArr: Array<any>) {
  let res: Array<number> = [];
  for (let index = 0; index < Arr.length; index++) {
    let flag = false;
    for (let j = 0; j < subArr.length; j++) {
      if (Arr[index].value === subArr[j].value) {
        flag = true;
        break;
      }
    }
    if (flag) { res.push(1); }
    else {
      res.push(0);
    }
  }
  var Mask = 0;
  for (let index = 0; index < res.length; index++) {
    if (res[index] === 1) {
      Mask += Math.pow(2, index);
    }
  }
  return Mask;
}

export function LoadFromStore(VarName: string) {
  const serializedState = localStorage.getItem(VarName);
  if (serializedState === null) return undefined;
  let state = JSON.parse(serializedState);
  return state;
}

export function ListOfObj2SelectOptions(array: Array<any>) {
  //  
  let result: Array<{ value: string, label: string, color: string, isFixed: boolean, isDisabled?: boolean }> = [];
  let i = 1;
  array.forEach(element => {
    //  
    if (typeof (element) === "object")
      result.push({ value: element[Object.keys(element)[0]], label: element[Object.keys(element)[1]], isFixed: true, color: 'black' });
    else {
      result.push({ value: element, label: element, isFixed: true, color: "black" });
      i++;
    }
  });
  //  
  return result;
}

export function MapSecondsToTime(val: any) {
  var valString = val + "";
  if (valString.length < 2) {
    return "0" + valString;
  } else {
    return valString;
  }
}
export function isNullOrUndefined(obj: any) {
  if (obj === null || obj === undefined) {
    return true;
  }
  return false;
}


export function isHtmlElement(obj: any) {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrome)
    let _ = obj instanceof HTMLElement;
    if (_ === null) { return false; }
    return true;
  }
  catch (e) {
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have (works on IE7)
    return false;
  }
}


export function NotSpecialType(element: string) {
  //if (element === undefined) { return true;}
  if (element.toLowerCase() === "string" || element.toLowerCase() === "bool" || element.toLowerCase() === "date" ||
    element.toLowerCase() === "int" || element.toLowerCase() === "datetime"
    || element.toLowerCase() === "email" || element.toLowerCase() === 'password' || element.toLowerCase() === 'phone'
  ) {
    return true;
  } else {
    return false;
  }
}