let domain = ""
if(window.location.origin.includes("iatseg") ){
    domain = "iatseg.org"
}else if(window.location.origin.includes("ics")) {
    domain = "ics.ivyis.org"
   }else if(window.location.origin.includes("ivyis")) {
 domain = "ivyis.org"
}else {
    domain = "localhost"
}

export const BASE_URL = domain == "localhost" ? "https://localhost/assessments_platform/backend/" : domain == "ivyis.org"  ? `https://q.ivyis.org/backend/` :domain == "ics.ivyis.org" ? "https://ics-e.ivyis.org/backend/" :"https://exam.iatseg.org/backend/"
export const REACT_APP_LOGIN = domain == "localhost" ? "https://localhost/assessments_platform/backend/login.php" : domain == "ivyis.org"  ? `https://q.ivyis.org/backend/login.php` : domain == "ics.ivyis.org" ? "https://ics-e.ivyis.org/backend/login.php" :" https://exam.iatseg.org/backend/login.php"
export const REACT_APP_LOGOUT = domain == "localhost" ? "https://localhost/assessments_platform/backend/logout.php" : domain == "ivyis.org"  ? `https://q.ivyis.org/backend/logout.php` : domain == "ics.ivyis.org" ? "https://ics-e.ivyis.org/backend/logout.php" :" https://exam.iatseg.org/backend/logout.php"


//NODE_ENV === 'development' ? "http://localhost/Students_Affairs/backend/" : 'https://sis.ivyis.org/backend/';