import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';

interface NormalDistributionChartProps {
    props_mean: number;
    props_std_dev: number;
    outof: number;
    save_new_score: (shift_score: number, mean: number, std_dev: number) => void;
    scores: number[];
}

const NormalDistributionChart: React.FC<NormalDistributionChartProps> = ({ props_mean, props_std_dev, outof, save_new_score, scores }) => {

    const [old_data, setOld_data] = useState<any>({})
    const [shift_score_state, setShift_score_state] = useState<number>(0)
    // stats to be shown =>   mean - medium - max - min - std dev ======================================= start
    function calculateStats(scores: number[]): [number, number, number] {
        const sortedScores = scores.sort((a, b) => a - b);
        const median = sortedScores[Math.floor(sortedScores?.length / 2)];
        const max = sortedScores[sortedScores?.length - 1];
        const min = sortedScores[0];
        return [median, max, min];
    }

    const [stats_old_mean, setStats_old_mean] = useState(0)
    const [stats_old_medium, setStats_old_medium] = useState(0)
    const [stats_old_max, setStats_old_max] = useState(0)
    const [stats_old_min, setStats_old_min] = useState(0)
    const [stats_old_std_dev, setStats_old_std_dev] = useState(0)

    const [stats_new_mean, setStats_new_mean] = useState(0)
    const [stats_new_medium, setStats_new_medium] = useState(0)
    const [stats_new_max, setStats_new_max] = useState(0)
    const [stats_new_min, setStats_new_min] = useState(0)
    const [stats_new_std_dev, setStats_new_std_dev] = useState(0)
    // stats  ===================================================================================================================== end


    // chart  ===================================================================================================================== start
    const [chartData, setChartData] = useState<any>([]);
    const chart_layout_factory = (width: number) => {
        return {
            title: 'Student scores',
            xaxis: { title: '' },
            yaxis: { title: '' },
            width,
            height: 500,
        }
    }
    const [chart_layout, setChart_layout] = useState(chart_layout_factory(0))
    const marker_size = 8
    const scale = 1
    // chart  ===================================================================================================================== end



    // to make the chart responsive  ===================================================================================================================== start
    const divRef = useRef<HTMLDivElement>(null);
    const handleResize = () => {
        if (divRef.current) {
            setChart_layout(chart_layout_factory(divRef?.current?.offsetWidth - 10))
        }
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [divRef]);
    // to make the chart responsive  ===================================================================================================================== end




    // equation functions  ===================================================================================================================== start
    const calculate_mean = (scores: number[]) => {
        return scores?.reduce((a, b) => a + b, 0) / scores?.length;
    }
    const calculate_stdDev = (mean: number, scores: number[]) => {
        return Math.sqrt(
            calculate_mean(scores?.map((x) => Math.pow(x - mean, 2)))
        );
    }
    const calculate_NormalDistribution = (inputX: number, mean: number, stdDev: number,) => {
        return (1 / (stdDev * Math.sqrt(2 * Math.PI))) *
            Math.exp(-1 * (inputX - mean) ** 2 / (2 * stdDev ** 2))
    }
    const calculate_data = (stdDev: number, mean: number,) => {
        const stepSize = stdDev / 10;
        const lowerBound = mean - 3 * stdDev;
        const upperBound = mean + 3 * stdDev;

        const xValues: number[] = [];
        const yValues: number[] = [];
        
        if (lowerBound < upperBound) {
            for (let x = lowerBound; x <= upperBound; x += stepSize) {
                
                xValues.push((x?.toFixed(2) as any) - 0);
                yValues.push(
                    calculate_NormalDistribution(x, mean, stdDev)
                );
            }
        }
        return { xValues, yValues }
    }
    // equation functions  ===================================================================================================================== end



    // create the data for the chart  ===================================================================================================================== start
    const create_shift_scores = (new_value_scaled: number) => {
       
        // to shift the scores
        const shift_scores = scores.map((s: number) => {
            let x = s + (new_value_scaled)
            if (x > outof)
                x = outof
            else if (x < 0)
                x = 0
            return x
        })
      
        // getting the mean and standard deviation
        const new_mean = calculate_mean(shift_scores)
        const stdDev = calculate_stdDev(new_mean, shift_scores)
        // data that will be shown
        const { xValues, yValues } = calculate_data(stdDev, new_mean)
        let new_line = {}
        let new_markers = {}
        if (xValues.length) {
            // configuring how it will be rendered
            new_line =
            {
                x: xValues,
                y: yValues,
                type: 'scatter',
                mode: 'lines',
                name: "New Scores",
                line: { color: 'red' },
            }
            new_markers =
            {
                x: shift_scores,
                y: shift_scores.map((x: number) => calculate_NormalDistribution(x, new_mean, stdDev)),
                type: 'scatter',
                name: "New Scores",
                mode: 'markers',
                marker: {
                    size: marker_size,
                },
                line: { color: '#FF8000' },
            }
            // setChartData((x: any) => [
            //     x[0],
            //     x[1],
            //     new_line,
            //     new_markers,
            // ])
            // setting the new stats of the new data
            const [median, max, min] = calculateStats(shift_scores);
            setStats_new_mean(new_mean)
            setStats_new_medium(median)
            setStats_new_max(max)
            setStats_new_min(min)
            setStats_new_std_dev(stdDev)


        }

        return { new_line, new_markers }
    }
    useEffect(() => {
        // console.table({ props_mean, props_std_dev })
        // to set the width of the chart for the first time
        handleResize()
        // getting the mean and standard deviation
        // let mean = 0
        // let stdDev = 0
        // mean = calculate_mean(scores)
        // stdDev = calculate_stdDev(mean, scores)
        let mean = 0
        let stdDev = 0
        if ((props_mean > 0) && (props_std_dev > 0)) {
            mean = props_mean - 0
            stdDev = props_std_dev - 0
        } else {
            mean = calculate_mean(scores)
            stdDev = calculate_stdDev(mean, scores)
        }



        // data that will be shown
        const { xValues, yValues } = calculate_data(stdDev, mean)
        // configuring how it will be rendered
        setOld_data({
            x: xValues,
            y: yValues,
            type: 'scatter',
            name: "Original Scores",
            mode: 'lines',
            line: { color: 'blue' },
        })
        // setting the stats of the old data
        const [median, max, min] = calculateStats(scores);
        setStats_old_mean(mean)
        setStats_old_medium(median)
        setStats_old_max(max)
        setStats_old_min(min)
        setStats_old_std_dev(stdDev)
    }, [scores]);
    const config_chart_data = (new_data_line: any, new_data_markers: any) => {
        if (new_data_line && Object.keys(new_data_line).length && new_data_markers && Object.keys(new_data_markers).length)
            setChartData([
                old_data,
                new_data_line,
                new_data_markers,
            ])
    }
    // create the data for the chart  ===================================================================================================================== end


    // handlers  ===================================================================================================================== end
    const slider_change_handler = (e: any) => {
        const new_value = e?.target?.value - 0
        setShift_score_state(new_value)
        const new_value_scaled = new_value / scale
        const { new_line, new_markers } = create_shift_scores(new_value_scaled)
        config_chart_data(new_line, new_markers)
    }
    useEffect(() => {
        const new_value_scaled = shift_score_state / scale
        const { new_line, new_markers } = create_shift_scores(new_value_scaled)
        config_chart_data(new_line, new_markers)
    }, [old_data])
    // handlers  ===================================================================================================================== end
    return (
        <div className='row' >
            <div ref={divRef} className="col-8">
                <Plot data={(chartData as any)} layout={(chart_layout as any)} config={{ scrollZoom: true, displaylogo: false, responsive: true }} />
            </div>
            <div className="col-4">
                <span>shift the scores:</span>
                <input type="range" min={`-${outof}`} max={outof} value={shift_score_state} className="slider form-control" id="myRange"
                    onChange={slider_change_handler}
                />
                <table className="table table-hover  table-striped">
                    <thead>
                        <tr>
                            <th scope="col">stats</th>
                            <th scope="col">value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-primary"><td>Original Mean: </td><td>{stats_old_mean?.toFixed(2)}</td></tr>
                        <tr className="table-danger"><td>New Mean: </td><td>{stats_new_mean?.toFixed(2)}</td></tr>
                        <tr className="table-primary"><td>Original Medium: </td><td>{stats_old_medium?.toFixed(2)}</td></tr>
                        <tr className="table-danger"><td>New Medium: </td><td>{stats_new_medium?.toFixed(2)}</td></tr>
                        <tr className="table-primary"><td>Original Max: </td><td>{stats_old_max?.toFixed(2)}</td></tr>
                        <tr className="table-danger"><td>New Max: </td><td>{stats_new_max?.toFixed(2)}</td></tr>
                        <tr className="table-primary"><td>Original Min: </td><td>{stats_old_min?.toFixed(2)}</td></tr>
                        <tr className="table-danger"><td>New Min: </td><td>{stats_new_min?.toFixed(2)}</td></tr>
                        <tr className="table-primary"><td>Original Standard Deviation: </td><td>{stats_old_std_dev?.toFixed(2)}</td></tr>
                        <tr className="table-danger"><td>New Standard Deviation: </td><td>{stats_new_std_dev?.toFixed(2)}</td></tr>
                    </tbody>
                </table>
                <button
                    className="col-12 btn btn-primary"
                    onClick={() => save_new_score(shift_score_state, stats_old_mean, stats_old_std_dev)}
                >
                    Save
                </button>
            </div>
        </div>
    )
};


export default NormalDistributionChart;
