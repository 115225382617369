import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios';

import Select from 'react-select';
import useCheckSuperAdmin from '../../hooks/useCheckSuperAdmin';
import { useParams } from 'react-router';


const SchoolSelectInfoBar = () => {
    // const [schoolSelectOptions, setSchoolSelectOptions] = useState<any>(
    //     [
    //         { id: '1', label: 'Fathalla', value: 'fathalla.iatseg.org' },
    //         { id: '2', label: 'Fresh', value: 'fresh.iatseg.org' },
    //         { id: '3', label: 'Sweedy', value: 'sweedy.iatseg.org' },
    //         { id: '4', label: 'Deifalla', value: 'deifalla.iatseg.org' },
    //     ]
    // )
    const schoolSelectOptions: any =
        [
             { id: '1', label: 'Fathallah Modern Trade', value: 'mt.iatseg.org' },
            
            { id: '2', label: 'Al-Sweedy (SW)', value: 'sw.iatseg.org' },
            { id: '3', label: 'Ahmed Diefalla AI', value: 'ai.iatseg.org' },
            { id: '4', label: 'Fresh (Mold)', value: 'md.iatseg.org' },
            { id: '5', label: 'Misr Insurance (Financial Servicees)', value: 'fs.iatseg.org' },
        ]

    const [selectedSchool, setSelectedSchool] = useState<any>()
    const ifSuperAdmin = useCheckSuperAdmin();
    


    useEffect(() => {
       
        if (ifSuperAdmin) {
            const searchParams = new URLSearchParams(document.location.search)
            let schoolName = searchParams.get('school_name')
            
            const schoolLocallyStored = localStorage.getItem("selected_school");
            if (schoolLocallyStored) {
                setSelectedSchool(JSON.parse(schoolLocallyStored))
                axios.defaults.headers.common['emaildomain'] = `${JSON.parse(schoolLocallyStored).value}`;

            } else {
                if(schoolName){
                    let found = schoolSelectOptions.find((school:any)=> school.value == schoolName)
                      setSelectedSchool(found)
                      localStorage.setItem('selected_school', JSON.stringify(found))
                      axios.defaults.headers.common['emaildomain'] = `${found.value}`;
                      window.location.reload()
                  }else{

                      setSelectedSchool(schoolSelectOptions[0])
                      localStorage.setItem('selected_school', JSON.stringify(schoolSelectOptions[0]))
                      window.location.reload()
                      axios.defaults.headers.common['emaildomain'] = `${schoolSelectOptions[0].value}`;
                    }

            }
        }
    }, [ifSuperAdmin])

    return (
        <>
            {ifSuperAdmin ? <div style={{ background: '#ffc107', display: 'flex', alignItems: 'baseline', width: '100%', paddingLeft: '10px', paddingRight: '10px' }
            }>
                <h5 style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                    {'You Are Currently Viewing As'}:
                </h5>
                <div style={{ marginBottom: "10px", marginTop: "10px", marginLeft: "10px", flex: '0.8', zIndex: 2000, fontSize: '1.2rem', fontWeight: 'bold' }}>
                    {/* <InputLabel
                    htmlFor=""
                    style={{ color: "#0c50a7", marginBottom: "10px", fontWeight: "bold" }}
                >
                    {"Chnage School"}:
                </InputLabel> */}
                    <Select
                        options={schoolSelectOptions}
                        onChange={(value: any) => {
                            setSelectedSchool(value);
                            localStorage.setItem('selected_school', JSON.stringify(value))
                            window.location.reload()
                        }}
                        placeholder={"Select School"}
                        value={selectedSchool}
                    />
                </div>

            </div>
                : <></>}
        </>
    )
}

export default SchoolSelectInfoBar