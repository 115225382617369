import React from 'react'
const table_cell_style: any = {
    padding: "10px",
    border: "1px solid",
    textAlign: "center",
};
const CardTable = (props: any) => {
    const addBtn = props.addBtn;
    return (
        <div
            style={{
                width: "100%",
            }}
        >
            {
                (addBtn) ?
                    <>{(addBtn)()}</>
                    :
                    null
            }
            <div className="col-12 row">

                <table
                    style={{
                        width: "100%",
                        color: "black",
                        background: "white",
                        padding: "10px",
                        // borderRadius: "10px",
                        borderCollapse: "collapse",
                    }}
                >
                    <thead>
                        {/* Header */}
                        <tr>
                            {
                                (
                                    () => {
                                        return props.headerArr.map((x_arr: any) =>
                                            <th
                                                key={x_arr}
                                                style={table_cell_style}
                                            >
                                                {x_arr}
                                            </th>);
                                    }
                                )()
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {/* Rows */}
                        {
                            props?.rowArr?.map((row: any, rowIndex: any) => {
                                return (<tr key={row.serial_hash}
                                >
                                    {

                                        row.arr.map((x_arr: any, x_arr_i: any) =>
                                            <td
                                                key={x_arr_i}
                                                style={table_cell_style}
                                            >
                                                {(x_arr)()}
                                            </td>)
                                    }
                                </tr>
                                )

                            })
                        }


                    </tbody>
                </table>

                {
                    // if there is no data 
                    (props?.rowArr?.length) ? null :
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "20px",
                                width: "100%",
                                padding: "10px",
                            }}
                        >
                            No Rows
                        </div>
                }

            </div>

            {/* table ------------------------------- end */}


        </div>
    );
}

export default CardTable